import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetAuth } from "./AuthContext";
import ModalErrorOccured from "./ModalErrorOccured";
import {ClipLoader} from 'react-spinners'

const LoginForm = ({ BASE_URL }) => {
  // const BASE_URL = "http://localhost:8000/";
  // const BASE_URL = "http://192.168.1.52:8000/";
  // const BASE_URL = "https://zback.liara.run/";
  const SetAuth = useSetAuth();
  const [myState, setMyState] = useState({
    password: "",
    username: "",
  });
  const navigate = useNavigate();
  const [wrongCreds, setWrongCreds] = useState(false);
  const [errorOccured, setErrorOccured] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const WrongCredsModal = (
    <Modal className="Vazirmatn" show={wrongCreds} onHide={() => setWrongCreds(false)} dir="auto">
      <Modal.Header>
        <Modal.Title>نام کاربری یا رمز عبور اشتباه است.</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        لطفا نام کاربری و رمز عبور خود را اصلاح کرده و دوباره اقدام کنید.
      </Modal.Body>
    </Modal>
  );

  const handleStateChange = (event) => {
    const eventName = event.target.name;
    const tmpState = { ...myState, [eventName]: event.target.value };
    setMyState(tmpState);
  };

  const handleRequest = (event) => {
    event.preventDefault();
    setIsLoading(true)
    // console.log(JSON.stringify(myState))
    const f = async () => {
      try {
        const res = await fetch(BASE_URL + "accounts/login/", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify(myState),
        });
        if (res.ok) {
          const result = await res.json();
          //Saving to LOCALSTORAGE
          window.localStorage.setItem("token", result.token);
          localStorage.setItem("authenticated", true);
          SetAuth(true);
          
          //#############################################################
          //redirect to chat page
          navigate("/chat");
        } else {
          setWrongCreds(true);
        }
        setIsLoading(false)

      } catch (error) {
        setErrorOccured(true);
        setIsLoading(false)
      }
    };
    f();
  };

  return (
    <>
      <ModalErrorOccured
        errorOccured={errorOccured}
        setErrorOccured={setErrorOccured}
      />
      <Form onSubmit={handleRequest} className="d-flex flex-column" dir="auto">
        {WrongCredsModal}
        <Form.Group className="mb-3" controlId="fromSignInUsername">
          <Form.Label>نام کاربری</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            onChange={handleStateChange}
            name="username"
            dir="ltr"
            disabled={isLoading}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formSignInPassword">
          <Form.Label>رمز عبور</Form.Label>
          <Form.Control
            type="password"
            placeholder=""
            onChange={handleStateChange}
            name="password"
            dir="ltr"
            disabled={isLoading}
          />
        </Form.Group>
        <Button
          className="mb-3 col-12 col-md-8 col-lg-6 align-self-center d-flex flex-row align-items-center"
          variant="primary"
          type="submit"
          disabled={isLoading}
        >
          <span className=" mx-auto">ورود</span>
          {isLoading && <ClipLoader className=" my-1" color="white" size={20} speedMultiplier={0.7}/>}
        </Button>
      </Form>
    </>
  );
};

export default LoginForm;
