import React from "react";
import HeroPagePic from "../../assets/HeroPagePic";
import Check from "../../assets/Check";
import { Col, Row } from "react-bootstrap";
import "../../pages/styles/Home.scss";
import CTAButton from "./CTAButton";

const SectionOne_HeroPage = ({ isDesktop }) => {
  // -----------------------------------------------------
  // Content
  const headline1 = "که نیاز دارید را";
  const headline2 = "هر لحظه";
  const headline3 = "که می‌خواهید دریافت کنید!";
  const headlineStyled = "حمایـتـی";

  const subHeadlineStyled = "بهبود دهنده‌ی سلامت روان.";
  const subHeadline1 = " هوش مصنوعی ما همیشه برای چت با شما اینجاست.";
  const subHeadline2 = "هوشمند. همدل. حمایتگر.";

  const result1 = "روابط رضایت‌بخش";
  const result2 = "آرامش ذهنی و کاهش اضطراب";
  const result3 = "مدیریت احساسات";
  // ----------------------------------------------------------

  if (isDesktop) {
    // -----------------------------------------Desktop--------------------------------------------
    return (
      <>
        <Row
          className="HomePage HeroPage text-light pb-4"
          style={{ paddingTop: "56px" }}
        >
          <Col className="heroCol col-xl-7 col-lg-7 d-flex flex-column align-items-center text-center">
            <h1 className="headline my-2 mt-5 fw-bold fs-2">
              <div>
                <span className="styledSpan1 fw-bold"> {headlineStyled} </span>
                {headline1}
              </div>
              <div>{headline2}</div>
              <div>{headline3}</div>
            </h1>
            <br />
            <h2 id="subHeadline1" className=" fw-medium fs-3">
              {subHeadline1}
            </h2>
            <h2 className="styledText fw-bold fs-2">{subHeadlineStyled}</h2>
            <h2 id="subHeadline2" className="fw-semibold fs-2">
              {subHeadline2}
            </h2>
            <CTAButton />
            <div>
              <div className=" d-flex justify-content-start">
                <div className=" check mx-2">
                  <Check />
                </div>
                <p className="  fw-light fs-5 mx-1">{result1}</p>
              </div>
              <div className=" d-flex justify-content-start">
                <div className=" check mx-2">
                  <Check />
                </div>
                <p className="  fw-light fs-5 mx-1">{result2}</p>
              </div>
              <div className=" d-flex justify-content-start">
                <div className=" check mx-2">
                  <Check />
                </div>
                <p className="  fw-light fs-5 mx-1">{result3}</p>
              </div>
            </div>
          </Col>
          <Col className=" col-xl-4 col-lg-5 mx-auto">
            <HeroPagePic />
          </Col>
        </Row>
      </>
    );
  } else {
    // -----------------------------------------Mobile--------------------------------------------
    return (
      <>
        <Row
          className="HomePage HeroPage text-light"
          style={{ paddingTop: "56px" }}
        >
          <Col className="heroCol w-100 d-flex flex-column align-items-center text-center mx-1">
            <h1 className="headline my-2 mt-5 fw-semibold fs-1">
              <div>
                <span className="styledSpan1 fw-bolder">
                  {" "}
                  {headlineStyled}{" "}
                </span>
                {headline1}
              </div>
              <div>{headline2}</div>
              <div>{headline3}</div>
            </h1>
            <div className="col-8 col-sm-7 col-md-6">
              <HeroPagePic />
            </div>
            <br />
            <h2 id="subHeadline1" className=" fw-medium fs-4 px-3">
              {subHeadline1}
            </h2>
            <h2 className="styledText fw-bold fs-2">{subHeadlineStyled}</h2>
            <h2 id="subHeadline2" className="fw-semibold fs-3">
              {subHeadline2}
            </h2>
            <CTAButton />
            <div>
              <div className=" d-flex justify-content-start">
                <div className=" check mx-2">
                  <Check />
                </div>
                <p className="  fw-light fs-6 mx-1">{result1}</p>
              </div>
              <div className=" d-flex justify-content-start">
                <div className=" check mx-2">
                  <Check />
                </div>
                <p className="  fw-light fs-6 mx-1">{result2}</p>
              </div>
              <div className=" d-flex justify-content-start">
                <div className=" check mx-2">
                  <Check />
                </div>
                <p className="  fw-light fs-6 mx-1">{result3}</p>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
};

export default SectionOne_HeroPage;
