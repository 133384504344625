import React from "react";
import { useNavigate } from "react-router-dom";

const CTAButton = ({ text }) => {
  const navigate = useNavigate();
  const CTA_text = "همین حالا چَت کنید!";
  if (!text) {
    text = CTA_text;
  }
  return (
    <>
      <button
        type="button"
        className=" btn-custom-primary my-3 fw-semibold fs-5"
        onClick={() => navigate("/chat")}
      >
        {text}
      </button>
    </>
  );
};

export default CTAButton;
