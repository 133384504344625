import React from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap'
const InputBox = ({ userMessage, setUserMessage, SendMessage, isTyping }) => {
  function handleKeyPress(target) {
    if (target.key === "Enter") {
      SendMessage()
    }
  }

  return (
    <>
      <InputGroup className="mb-3 mt-2 " dir='ltr'>
        <Form.Control
          dir='auto'
          placeholder="پیام خود را اینجا بنویسید"
          aria-label="Message"
          aria-describedby="basic-addon2"
          onChange={(event) => {
            setUserMessage(event.target.value)
          }}
          onKeyDown={handleKeyPress}
          value={userMessage}
          disabled={isTyping}
          autoComplete={'off'}
        />
        <Button variant="primary" id="button-addon2" onClick={SendMessage} disabled={isTyping}>
          ارسال
        </Button>
      </InputGroup>
    </>
  )
}

export default InputBox