import React from 'react'

const Check = () => {
  return (
    <>
      <svg width="100%" height="100%" viewBox="0 0 62 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_8_46)">
        <g filter="url(#filter1_d_8_46)">
        <path d="M22.1005 42.1555L4.78042 23.415C3.73986 22.2891 3.73986 20.4636 4.78042 19.3376L8.54868 15.2602C9.58924 14.1342 11.2765 14.1342 12.317 15.2602L23.9847 27.8846L48.9755 0.84442C50.016 -0.281473 51.7033 -0.281473 52.7438 0.84442L56.5121 4.92183C57.5526 6.04772 57.5526 7.87323 56.5121 8.99923L25.8689 42.1556C24.8282 43.2815 23.1411 43.2815 22.1005 42.1555Z" fill="url(#paint0_linear_8_46)"/>
        </g>
        </g>
        <defs>
        <filter id="filter0_d_8_46" x="0" y="0" width="61.2925" height="51" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8_46"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8_46" result="shape"/>
        </filter>
        <filter id="filter1_d_8_46" x="0" y="0" width="61.2925" height="51" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8_46"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8_46" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_8_46" x1="4" y1="21.7216" x2="57.2925" y2="21.7216" gradientUnits="userSpaceOnUse">
        <stop offset="0.261073" stopColor="#161B62"/>
        <stop offset="1" stopColor="#6935FD"/>
        </linearGradient>
        </defs>
      </svg>

    </>
  )
}

export default Check