import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./styles/Home.scss";
import NavBar from "../components/NavBar";
import { useMediaQuery } from "react-responsive";
import SectionOne_HeroPage from "../components/Homepage/SectionOne_HeroPage";
import SectionTwo_Problem_Solution from "../components/Homepage/SectionTwo_Problem_Solution";
import SectionThreeBenefits from "../components/Homepage/SectionThreeBenefits";
import SectionSixFAQ from "../components/Homepage/SectionSixFAQ";
import CTAButton from "../components/Homepage/CTAButton";
import Disclaimer from "../components/Homepage/Disclaimer";

const Home = ({ BASE_URL }) => {
  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });

  const callToAction = "رایگان شروع کنید!";

  const CTA_Section = (
    <Row className="HomePage CallToAction text-light py-5">
      <Col className=" d-flex flex-column align-items-center">
        <h1>{callToAction}</h1>
        <CTAButton />
      </Col>
    </Row>
  );
  return (
    <>
      <Container fluid className="h-100 w-100" dir="rtl">
        <Row>
          <NavBar BASE_URL={BASE_URL}/>
        </Row>
        <SectionOne_HeroPage isDesktop={isDesktop} />
        <SectionTwo_Problem_Solution isDesktop={isDesktop} />
        <SectionThreeBenefits isDesktop={isDesktop} />
        <SectionSixFAQ />
        {CTA_Section}
        <Disclaimer />
      </Container>
    </>
  );
};

export default Home;
