import React from "react";
import { Nav, Container, Button, Row, Col } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";
import { useAuth, useSetAuth } from "./AuthContext";
import { useMediaQuery } from "react-responsive";
import Logo from "../assets/Logo";
import "./Style/Navbar.scss";

const NavBar = ({ BASE_URL }) => {
  // const BASE_URL = "http://localhost:8000/";
  // const BASE_URL = "http://192.168.1.52:8000/";
  // const BASE_URL = "https://zback.liara.run/";

  const isAuthenticated = useAuth();
  const SetAuth = useSetAuth();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });
  const logOut = () => {
    const f = async () => {
      const getToken = window.localStorage.getItem("token");
      const res = await fetch(BASE_URL + "accounts/logout/", {
        method: "POST",
        headers: {
          Authorization: `Token ${getToken}`,
        },
      });
      const response = await res.text();
      // console.log(response, res.status);
      //Removing from LOCALSTORAGE
      window.localStorage.removeItem("token");
      SetAuth(false);
      localStorage.setItem("authenticated", false);
      //#############################################################
      //redirect to Home page
      navigate("/");
    };
    f();
  };

  return (
    <>
      {isDesktop 
      ?
      <Navbar
        id="navbar"
        expand="lg"
        bg="dark"
        data-bs-theme="dark"
        fixed="top"
        className="HomePage"
        dir="rtl"
      >
        <Container>
          <Navbar.Brand
            href="#"
            className="d-flex my-0"
            dir="ltr"
            onClick={() => navigate("/")}
          >
            <div className="mx-3" style={{ height: "3rem" , width: "3rem"}}>
              <Logo />
            </div>
            <h1 className="styledSpan1 BrandName my-auto text-end">
              Zehnician
            </h1>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#home">صفحه اصلی</Nav.Link>
              <Nav.Link href="#link">مقاله‌ها</Nav.Link>
            </Nav>
            {isAuthenticated ? (
              <>
                <button
                  type="button"
                  className=" btn-custom-primary my-1 fw-semibold fs-6 mx-2"
                  style={{ width: "4rem", height: "2rem", borderRadius: "5px" }}
                  onClick={() => navigate("/chat")}
                >
                  چت
                </button>
                <Button
                  className="py-1 my-2 my-lg-1"
                  variant="secondary"
                  size="sm"
                  onClick={logOut}
                >
                  خروج
                </Button>
              </>
            ) : (
              <button
                type="button"
                className=" btn-custom-primary my-1 fw-semibold fs-6"
                style={{ width: "8rem", height: "2rem" }}
                onClick={() => navigate("/auth")}
              >
                ثبت نام / ورود
              </button>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar> 
      :
      <Navbar
        id="navbar"
        expand="lg"
        bg="dark"
        data-bs-theme="dark"
        fixed="top"
        className="HomePage"
        dir="rtl"
      >
        <Container className="">
          <Row className=" h-auto w-100 mx-0">
            <Col className="col-4 d-flex justify-content-center align-items-center">
              <Navbar.Brand
              href="#"
              className="mx-0 my-0"
              dir="ltr"
              onClick={() => navigate("/")}
              
            >
              <div className="" style={{ height: "3rem" }}>
                <Logo />
              </div>
            </Navbar.Brand>
            </Col>
            <Col className="col-4 d-flex justify-content-center align-items-center">
              <Navbar.Brand
                href="#"
                className="mx-0 my-0"
                dir="ltr"
                onClick={() => navigate("/")}
              >
              <h1 className="styledSpan1 BrandName my-0">
                Zehnician
              </h1>
            </Navbar.Brand>
            </Col>
            <Col className="col-4 d-flex justify-content-center align-items-center">
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="" />
            </Col>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#home">صفحه اصلی</Nav.Link>
              <Nav.Link href="#link">مقاله‌ها</Nav.Link>
            </Nav>
            {isAuthenticated ? (
              <>
                <button
                  type="button"
                  className=" btn-custom-primary my-1 fw-semibold fs-6 mx-2"
                  style={{ width: "4rem", height: "2rem", borderRadius: "5px" }}
                  onClick={() => navigate("/chat")}
                >
                  چت
                </button>
                <Button
                  className="py-1 my-2 my-lg-1"
                  variant="secondary"
                  size="sm"
                  onClick={logOut}
                >
                  خروج
                </Button>
              </>
            ) : (
              <button
                type="button"
                className=" btn-custom-primary my-1 fw-semibold fs-6"
                style={{ width: "8rem", height: "2rem" }}
                onClick={() => navigate("/auth")}
              >
                ثبت نام / ورود
              </button>
            )}
          </Navbar.Collapse>
          </Row>
        </Container>
      </Navbar>
      }
    </>
  );
};

export default NavBar;
