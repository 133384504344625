import React, { useContext, useState, useEffect } from "react";

const AuthContext = React.createContext();
const AuthContextUpdate = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}
export function useSetAuth() {
  return useContext(AuthContextUpdate);
}

const AuthContextProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState();

  const SetAuth = (auth) => {
    setAuthenticated(auth);
  };
  const isAuthenticated = useAuth();
  useEffect(() => {
    const localAuth = localStorage.getItem("authenticated") === "true";
    if (localAuth) {
      SetAuth(true);
    } else {
      SetAuth(false);
    }
  }, [isAuthenticated, SetAuth]);

  return (
    <AuthContext.Provider value={authenticated}>
      <AuthContextUpdate.Provider value={SetAuth}>
        {children}
      </AuthContextUpdate.Provider>
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
