import { Col, Row, Container, Button } from "react-bootstrap";
import { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../components/AuthContext";
import "./styles/Auth.scss";

const Authentication = ({ BASE_URL }) => {
  const navigate = useNavigate();
  const isAuthenticated = useAuth();
  useEffect(() => {
    const getToken = window.localStorage.getItem("token");

    if (getToken != null && isAuthenticated) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <Container fluid className="Vazirmatn h-100 pageBg" dir="auto">
        <Row className="d-flex justify-content-center h-100">
          <Col xs={12} md={8} lg={6} className="d-flex justify-content-center">
            <Row className=" my-auto w-100 d-flex flex-row justify-content-center">
              <Button
                className="col-4 mx-2"
                variant="primary"
                onClick={() => navigate("signup")}
              >
                ثبت نام
              </Button>
              <Button
                className="col-4 mx-2"
                variant="success"
                onClick={() => navigate("login")}
              >
                ورود
              </Button>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Authentication;
