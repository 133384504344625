import React from 'react'
import Nav from 'react-bootstrap/Nav';
import { Button } from 'react-bootstrap';
import {FaPlus} from 'react-icons/fa'
import {PiSidebarSimple} from 'react-icons/pi'
import {Row, Col} from 'react-bootstrap';
import {HashLoader} from 'react-spinners';

const Sidebar = ({sessions, FetchMessages, setShowSidebar, NewSession, loadingSessions}) => {
  return (
    <>
      <Nav id='sideBar' className=" flex-column pl-0 "  variant='pills' style={{}}>
        <div className=' d-flex justify-content-between my-2'>
          <Button className='col-4 mx-2 d-flex justify-content-center align-items-center' size='sm' variant="outline-light" onClick={() => setShowSidebar(false)}>
            <PiSidebarSimple size={30}/>
          </Button>
          <Button className='col-4 mx-2 d-flex  justify-content-center align-items-center'  size='sm' variant="outline-light"  onClick={NewSession}>
            <FaPlus size={20}/>
          </Button>
        </div>
      
        {loadingSessions ? 
          <>
            <Row className="col-12 d-flex align-items-center h-50 text-center text-light mx-0 my-2">
              <Col className=" d-flex flex-column align-items-center">
                <HashLoader className=" my-4 mx-auto" color="white" size={30} speedMultiplier={0.7}/>
                <span>Loading</span>
              </Col>
            </Row>
          </>
        :
        sessions.length === 0 ?
        <Row className="col-12 d-flex align-items-center h-50 text-center text-light mx-0 my-3" dir='auto'>
          <span>شما هنوز جلسه‌ای ندارید، برای ایجاد جلسه + را بزنید.</span>
        </Row>
        
        :
        sessions.map((mySession, index) => {
          return(
            <Nav.Item key={index+1}>
              <Nav.Link id='sidebarlink' eventKey={index+1}
              href={'#'} 
              className='text-white border border-light mx-2 my-1' 
              onClick={() => FetchMessages(mySession.id)}
                >{mySession.name}</Nav.Link>
            </Nav.Item>
          )
        })}
      </Nav>
      
    </>
  )
}

export default Sidebar