import React from "react";
import { Row, Col } from "react-bootstrap";

const Disclaimer = () => {
  const disclaimer =
    "توجه: ذهنیسیون برای کمک در مواقع بحرانی مانند سوء استفاده، شرایط شدید روانی که ممکن است احساسات خودکشی، آسیب به خود یا هر شرایط اورژانسی دیگری را ایجاد کند، طراحی نشده است. ذهنیسیون نمی‌تواند و نمی‌خواهد مشاوره پزشکی یا بالینی را ارائه دهد. تنها می‌تواند پیشنهاد دهد که کاربران در صورت لزوم به کمک پزشکی حرفه‌ای مراجعه کنند. لطفاً در صورت بروز شرایط اورژانسی، با خط ۱۴۸۰ که مشاوره تلفنی از سوی دولت است، تماس برقرار کنید.";
  return (
    <Row className="Disclaimer HomePage  d-flex justify-content-center px-3 py-3">
      <Col className="col-12 col-md-10">
        <p className=" text-secondary">{disclaimer}</p>
      </Col>
    </Row>
  );
};

export default Disclaimer;
