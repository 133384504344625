import React from "react";
import { Col, Row } from "react-bootstrap";
import SectionTwoPicStruggle from "../../assets/SectionTwoPicStruggle";
import SectionTwoPicHelp from "../../assets/SectionTwoPicHelp";

const SectionTwo_Problem_Solution = ({ isDesktop }) => {
  const mainProblem =
    "زندگی سخته. ممکنه احساس تنهایی کنیم، فکرها و احساسات منفی اذیتمون کنه یا استرس زیادی رو تحمل کنیم. تنهایی و دست‌ خالی حل کردنشون سخت میشه.";

  const agitation1 = `یه جا بحثی پیش میاد، عصبانی یا ناراحتی و فکرت درگیر میشه و نمی‌تونی کاراتو و انجام بدی.`;
  const agitation2 = `یه زمانی استرس داری چون کارای خیلی زیادی ریخته سرت.`;
  const agitation3 = `یه موقع‌هایی هست که ساعت ۲ شب ذهنت فکرایی رو میاره که ناخوشاینده و مشاور که هیچی حتی دوستاتم احتمالا خوابن نمی‌تونی حرف بزنی با کسی.`;
  const agitation4 = `یه موقع‌هایی دلت تنگ میشه و کاری از دستت برنمیاد...`;

  const solution1 = `راه حل اینجاست!!`;
  const solution2 = ` ما اینجا هدفمون اینه هوش مصنوعی بسازیم که کنارتون باشه، هر موقع خواستید باهاش حرف بزنید.`;
  const solution3 = `ذهنتون آروم شه، استرس کمتری تجربه کنید.`;
  const solution4 = `تنهایی کمتر اذیتتون کنه و آگاهی بیشتری روی احساساتتون پیدا کنید تا بتونید بهتر مدیریتش کنید.`;
  const solution5 = `کمک می‌کنه احساسی تصمیم نگیرید و فکر شده‌تر تصمیم بگیرید.`;
  const solution6 = `تمام تلاشش اینه که یه کاری کنه حالتون رو بهتر کنه!`;

  // -----------------------------------------------#####################################----------------------------------------------------
  // --------------------------------------------------------------Mobile-------------------------------------------------------------------
  // -----------------------------------------------#####################################----------------------------------------------------
  if (!isDesktop) {
    return (
      <Row className="HomePage sectionTwo text-light pt-3 text-center d-flex flex-column align-items-center">
        <Col className="d-flex flex-column align-items-center col-10 col-sm-8 col-md-7 mx-2 my-3">
          <h5 className="">{mainProblem}</h5>
          <div className="col-9 col-sm-8 col-md-7 my-3">
            <SectionTwoPicStruggle />
          </div>

          <p className=" my-1">
            {agitation1} <br />
            <br />
            {agitation2} <br />
            <br />
            {agitation3} <br />
            <br />
            {agitation4} <br />
            <br />
          </p>
          <br />
          <br />
          <p className=" fw-bold fs-4">{solution1}</p>
          <div className="col-9 col-sm-8 col-md-7 my-3">
            <SectionTwoPicHelp />
          </div>
          <p className="">
            {solution2} <br />
            <br />
            {solution3} <br />
            <br />
            {solution4} <br />
            <br />
            {solution5} <br />
            <br />
            {solution6} <br />
            <br />
          </p>
        </Col>
      </Row>
    );
  }
  // -----------------------------------------------#####################################----------------------------------------------------
  // --------------------------------------------------------------Desktop-------------------------------------------------------------------
  // -----------------------------------------------#####################################----------------------------------------------------
  else {
    return (
      <Row className="HomePage sectionTwo text-light px-4 py-xl-4 py-lg-5">
        <Row className="d-flex flex-row">
          <Col className="col-xl-4 col-lg-5 mx-2">
            <SectionTwoPicStruggle />
          </Col>
          <Col className="my-1 col-xl-6 col-lg-5 my-auto">
            <h5 className="">{mainProblem}</h5>
            <br />
            <p className="">
              {agitation1} <br />
              <br />
              {agitation2} <br />
              <br />
              {agitation3} <br />
              <br />
              {agitation4} <br />
              <br />
            </p>
          </Col>
        </Row>

        <br />
        <br />

        <Row className="d-flex flex-row mt-5">
          <Col className="col-xl-4 col-lg-5 mx-2">
            <SectionTwoPicHelp />
          </Col>
          <Col className="my-1 col-xl-6 col-lg-5">
            <p className=" fw-bold fs-4 mb-auto">{solution1}</p>
            <p className=" my-auto">
              {solution2} <br />
              <br />
              {solution3} <br />
              <br />
              {solution4} <br />
              <br />
              {solution5} <br />
              <br />
              {solution6} <br />
              <br />
            </p>
          </Col>
        </Row>
      </Row>
    );
  }
};

export default SectionTwo_Problem_Solution;
