import React from "react";
import { Accordion, Row, Col } from "react-bootstrap";

const SectionSixFAQ = () => {
  const faqs = [];

  let header1 = "ذهنیسیون چیه؟";
  let body1 =
    "ذهنیسیون یه هوش مصنوعیه که باهاتون چت میکنه و حمایتتون میکنه. از روش‌های مختلف استفاده میکنه تا کمکتون کنه استرستون کم شه، کمتر احساس تنهایی کنید، بتونید درک بهتری از احساستون داشته باشید، ذهنتون رو خالی کنید و تصمیم‌های درست بگیرید. با زبان های مختلف میتونید باهاش صحبت کنید اما انگلیسی رو از همه بهتر صحبت میکنه. فعلا میتونه گفت و گو کنه و سعی میکنه شناختتون از حس‌هاتون بیشتر شه و ذهنتون خالی شه و وقتی بخواید هم میتونه راهکار‌هایی با توجه به بحثتون بده. در آینده از راهکار‌های روانشناسی مثل CBT و در کنارش تمرین‌هایی برای بهبود عزت نفس یا غلبه بر ترس‌ها براتون میاره تا بتونید روی خودتون کار کنید و یک شخصیت فوق‌العاده از خودتون بسازید و حس خفن به خودتون و زندگیتون داشته باشید.";
  faqs.push({ header1, body1 });

  header1 = "من که تراپی میرم خودم به چه دردم میخوره آخه؟";
  body1 =
    "ممکنه شما زمانی که مسئله‌ای براتون پیش میاد تا جلسه‌ی مشاورتون چندین روز طول بکشه و شاید اصلاً زودتر نیاز دارید که حل کنید این مسئله رو اما دسترسی به روانشناستون نداشته باشید، ذهنیسیون یک ابزار هوشمنده که همیشه کنارتونه و هزینه‌اش هم شاید یک دهم هزینه مشاوره‌تون بشه در ماه و در کنار مشاوره می‌تونه خیلی بهتون کمک کنه.";
  faqs.push({ header1, body1 });

  header1 = "آیا مزیت خاصی داره؟";
  body1 =
    "همیشه در دسترس شماس. امنه. با توجه به ارزشی که وارد زندگیتون میکنه هزینه‌اش واقعا مقرون به صرفه است و همیشه دنبال روش‌هاییه که بتونه کمک کنه شما از خودتون یه شخصیت فوق‌العاده بسازید و از زندگی بسیار لذت بیشتری ببرید.";
  faqs.push({ header1, body1 });

  header1 = "من که تنها نیستم دوست‌های خودمو دارم، این به چه دردم میخوره؟";
  body1 =
    "خیلی خوبه که تنها نیستید و دوستانی دارید که می‌تونید روشون حساب کنید. حالا ممکنه دوستاتون که بخواهید باهاشون صحبت کنید در لحظه سرکار باشند، یا نصفه شب باشه خواب باشند یا به هر طریقی در دسترس نباشند اما ذهنیسیون همیشه آماده صحبت با شماست. در کنارش ممکنه دوستاتون واقعا بخوان کمکتون کنن اما ندونن چجوری و خب برای مشکلات شما ذهنیسیون از روش‌های علمی کارآمد برای کمک به شما استفاده می‌کند که بسیار می‌تواند به شما کمک کند.";
  faqs.push({ header1, body1 });

  header1 = "بدم نمیاد تست کنم ولی آخه اگه تست کنم به دردم نخوره چی؟";
  body1 =
    "o	خریدش هیچ ریسکی نداره به دلیل گارانتی کامل بازگشت وجه. برای این محصول می‌تونید یک روز رایگان تست کنید و برای استفاده بیشتر می‌تونید اشتراک تهیه کنید که اشتراک یک ماه ما گارانتی بازگشت کامل وجه داره. یعنی از زمانی که اشتراک شما فعال میشه تا ۳۰ روز که مهلت استفاده هست، اگر احساس کردید واقعا به دردتون نخورد میتونید پولتون رو کامل پس بگیرید.";
  faqs.push({ header1, body1 });

  header1 = "خب اگه نخرم اینو چی میشه؟";
  body1 =
    "باور کنید هیچ اتفاق خاصی نمیوفته و همه چیز همانطور که هستند باقی می‌ماند. در واقع علت اصلی که این ابزار رو پدید آوردیم اینه که بتونید کار متفاوتی کنید و در نتیجه کیفیت زندگیتون رو ارتقا بدید.";
  faqs.push({ header1, body1 });

  header1 = "هزینه‌ی ذهنیسیون به چه شکل است؟";
  body1 =
    "شما می‌توانید اشتراک تهیه کنید که بسته‌های اشتراکی ما در صفحه خرید اشتراک مشاهده میکنید.";
  faqs.push({ header1, body1 });

  header1 = "خب وقتی چت جی پی تی هست چرا واسه این هزینه کنم؟";
  body1 =
    "مدل‌های بزرگ متنی مانند چت جی پی تی به صورت عمومی آموزش دیده‌اند و می‌توانند سوالاتتان را در هر حیطه‌ای پاسخ دهند و دانش زیادی دارند اما به طور ویژه برای موارد خاص مثل این محصول طراحی نشده‌اند. در واقع می‌توانند راه‌حل هایی که در اینترنت هستند را به شما به طور خلاصه بگویند اما این محصول منحصراً برای بهبود سلامت روان شما با متود های علمی طراحی شده است.";
  faqs.push({ header1, body1 });

  // header1 = ''
  // body1 = ''
  // faqs.push({header1, body1})

  return (
    <Row className="HomePage FAQ text-light py-3 px-2" dir="rtl">
      <Col className="col-12 col-lg-8">
        <h3 className=" mx-3 py-3">سوالات متداول</h3>
        <Accordion
          className="my-2 mx-2 text-light text-wrap text-end"
          dir="rtl"
        >
          {faqs.map((item, index) => {
            return (
              <Accordion.Item eventKey={`${index}`} key={`${index}`}>
                <Accordion.Header
                  className="px-2"
                  style={{ textAlign: "right !important" }}
                >
                  {item.header1}
                </Accordion.Header>
                <Accordion.Body>{item.body1}</Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </Col>
    </Row>
  );
};

export default SectionSixFAQ;
