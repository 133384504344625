import React, { useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSetAuth } from "./AuthContext";
import {ClipLoader} from 'react-spinners'
import ModalErrorOccured from "./ModalErrorOccured";
const SignUpForm = ({ BASE_URL }) => {
  // const BASE_URL = "http://localhost:8000/";
  // const BASE_URL = "http://192.168.1.52:8000/";
  // const BASE_URL = "https://zback.liara.run/";
  const SetAuth = useSetAuth();
  const [myState, setMyState] = useState({
    username: "",
    password: "",
    email: "",
  });
  const [isLoading, setIsLoading] = useState(false)
  const [errorOccured, setErrorOccured] = useState(false);
  const [wrongCreds, setWrongCreds] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const handleStateChange = (event) => {
    const eventName = event.target.name;
    const tmpState = { ...myState, [eventName]: event.target.value };
    setMyState(tmpState);
  };
  const WrongCredsModal = (
    <Modal className="Vazirmatn" show={wrongCreds} onHide={() => setWrongCreds(false)} dir="auto">
      <Modal.Header>
        <Modal.Title>مشکلی در ثبت نام پیش آمده است.</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage === '' ? 'لطفا دوباره اقدام کنید.' : errorMessage}
        
      </Modal.Body>
    </Modal>
  );

  const login = async () => {
    const { username, password } = myState;
    const state = {
      username: username,
      password: password,
    };
    setIsLoading(true)
    const res = await fetch(BASE_URL + "accounts/login/", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(state),
    });
    const result = await res.json();
    //Saving to LOCALSTORAGE
    window.localStorage.setItem("token", result.token);
    localStorage.setItem("authenticated", true);
    SetAuth(true);
    setIsLoading(false)
    //#############################################################
    //redirect to chat page
    navigate("/chat");
  };

  const handleRequest = (event) => {
    event.preventDefault();
    // console.log(JSON.stringify(myState));
    setIsLoading(true)
    const f = async() => {
      try{
        const res = await fetch(BASE_URL + "accounts/register/", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify(myState),
        });
        
        if (res.ok){
          login();
        }
        else{
          const result = await res.json();
          setWrongCreds(true);
          if ('email' in result){
            if (result.email[0] == "user with this email already exists."){
              setErrorMessage('حساب کاربری با این ایمیل وجود دارد.')
            }
          }
        }
      }catch{
        setErrorOccured(true);
      }
      setIsLoading(false)
    };
    f();
    }
    

  return (
    <>
      <ModalErrorOccured
      errorOccured={errorOccured}
      setErrorOccured={setErrorOccured}
      />
      {WrongCredsModal}
      <Form className="d-flex flex-column" onSubmit={handleRequest} dir="auto">
        <Form.Group className="mb-3" controlId="formSignUpEmail">
          <Form.Label>آدرس ایمیل</Form.Label>
          <Form.Control
            type="email"
            placeholder=""
            onChange={handleStateChange}
            name="email"
            dir="ltr"
            disabled={isLoading}
          />
          <Form.Text className="text-muted"></Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="fromSignUpUsername">
          <Form.Label>نام کاربری</Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            onChange={handleStateChange}
            name="username"
            dir="ltr"
            disabled={isLoading}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formSignUpPassword">
          <Form.Label>رمز عبور</Form.Label>
          <Form.Control
            type="password"
            placeholder=""
            onChange={handleStateChange}
            name="password"
            dir="ltr"
            disabled={isLoading}
          />
          <Form.Text className="text-muted"></Form.Text>
        </Form.Group>
        <Button
          className=" d-flex flex-row align-items-center mb-3 col-12 col-md-8 col-lg-6 align-self-center"
          variant="primary"
          type="submit"
          disabled={isLoading}
        >
            <span className=" mx-auto">ثبت نام</span>
            {isLoading && <ClipLoader className=" my-1" color="white" size={20} speedMultiplier={0.7}/>}
        </Button>
        
      </Form>
    </>
  );
};

export default SignUpForm;
