import React from "react";
import SignUpForm from "../components/SignUpForm";
import { Card, Col, Row, Container} from "react-bootstrap";
import "./styles/Auth.scss";

const Signup = ({ BASE_URL }) => {
  return (
    <>
      <Container fluid className="Vazirmatn h-100 pageBg" dir="auto">
        <Row className="d-flex justify-content-center h-100">
          <Col
            xs={12}
            sm={10}
            md={8}
            lg={6}
            className="d-flex justify-content-center"
          >
            <Card className="shadow-lg my-auto w-100 text-light">
              <Card.Header className=" text-center CardHeader">
                <h2 className=" my-2">ایجاد حساب کاربری جدید</h2>
              </Card.Header>
              <Card.Body className="CardBody">
                <SignUpForm BASE_URL={BASE_URL}/>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Signup;
