import React from 'react'
const HeroPagePic = () => {
  return (
    <>
      <svg className='my-answer-rafiki' width="100%" height="100%" viewBox="0 0 790 802" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M790 613.37H0V613.771H790V613.37Z" fill="#EBEBEB"/>
        <path d="M710.842 639.178H658.512V639.579H710.842V639.178Z" fill="#EBEBEB"/>
        <path d="M523.328 643.541H509.597V643.942H523.328V643.541Z" fill="#EBEBEB"/>
        <path d="M656.932 624.293H626.612V624.694H656.932V624.293Z" fill="#EBEBEB"/>
        <path d="M151.127 626.988H82.8868V627.389H151.127V626.988Z" fill="#EBEBEB"/>
        <path d="M175.206 626.988H165.205V627.389H175.206V626.988Z" fill="#EBEBEB"/>
        <path d="M355.737 633.756H207.723V634.157H355.737V633.756Z" fill="#EBEBEB"/>
        <path d="M374.46 541.831H69.3778C66.9878 541.827 64.6971 540.86 63.0086 539.143C61.3201 537.426 60.3718 535.099 60.3718 532.672V97.2986C60.3926 94.8863 61.3501 92.5796 63.0364 90.8798C64.7226 89.1799 67.0014 88.2241 69.3778 88.22H374.46C376.853 88.22 379.147 89.1849 380.839 90.9026C382.531 92.6202 383.482 94.9498 383.482 97.3788V532.672C383.482 535.101 382.531 537.431 380.839 539.149C379.147 540.866 376.853 541.831 374.46 541.831ZM69.3778 88.5408C67.0926 88.5451 64.9024 89.4696 63.2879 91.1116C61.6735 92.7535 60.7668 94.9787 60.7668 97.2986V532.672C60.7668 534.992 61.6735 537.217 63.2879 538.859C64.9024 540.501 67.0926 541.426 69.3778 541.43H374.46C376.747 541.426 378.939 540.502 380.555 538.86C382.172 537.219 383.083 534.994 383.087 532.672V97.2986C383.083 94.9772 382.172 92.7521 380.555 91.1106C378.939 89.4691 376.747 88.545 374.46 88.5408H69.3778Z" fill="#EBEBEB"/>
        <path d="M716.23 541.831H411.132C408.74 541.827 406.448 540.861 404.757 539.144C403.066 537.427 402.114 535.1 402.11 532.672V97.2986C402.135 94.8848 403.096 92.5782 404.785 90.8788C406.473 89.1794 408.754 88.2241 411.132 88.22H716.23C718.603 88.2283 720.878 89.186 722.561 90.8854C724.244 92.5848 725.199 94.889 725.22 97.2986V532.672C725.22 535.096 724.274 537.421 722.589 539.137C720.904 540.854 718.617 541.823 716.23 541.831ZM411.132 88.5408C408.845 88.545 406.653 89.4691 405.036 91.1106C403.419 92.7521 402.509 94.9772 402.505 97.2986V532.672C402.509 534.994 403.419 537.219 405.036 538.86C406.653 540.502 408.845 541.426 411.132 541.43H716.23C718.516 541.426 720.708 540.502 722.325 538.86C723.942 537.219 724.852 534.994 724.857 532.672V97.2986C724.852 94.9772 723.942 92.7521 722.325 91.1106C720.708 89.4691 718.516 88.545 716.23 88.5408H411.132Z" fill="#EBEBEB"/>
        <path d="M436.807 565.506L583.336 535.945C584.251 535.758 585.194 535.756 586.109 535.94C587.025 536.123 587.896 536.488 588.673 537.014C589.449 537.54 590.116 538.217 590.634 539.005C591.153 539.793 591.513 540.677 591.694 541.607L596.719 567.271L599.373 580.824L600.4 586.086L594.191 581.867L445.86 611.798C444.944 611.984 444 611.985 443.084 611.801C442.167 611.616 441.295 611.25 440.519 610.722C439.742 610.194 439.076 609.516 438.558 608.726C438.04 607.936 437.681 607.051 437.502 606.12L431.182 574.04C430.991 573.104 430.986 572.139 431.167 571.201C431.347 570.263 431.71 569.371 432.233 568.577C432.757 567.782 433.431 567.102 434.216 566.575C435.001 566.048 435.882 565.684 436.807 565.506Z" fill="#F5F5F5"/>
        <path d="M322.32 384.96H155.93C147.267 384.956 138.957 388.444 132.827 394.658C126.697 400.873 123.248 409.304 123.24 418.099V549.963H354.931V418.099C354.923 409.318 351.485 400.899 345.372 394.687C339.259 388.475 330.969 384.977 322.32 384.96Z" fill="#E6E6E6"/>
        <path d="M404.101 482.66H374.334C371.784 482.66 369.26 483.17 366.905 484.16C364.55 485.151 362.41 486.604 360.609 488.434C358.807 490.265 357.378 492.439 356.404 494.83C355.43 497.222 354.929 499.785 354.931 502.373V611.445H423.519V502.373C423.519 497.145 421.473 492.13 417.832 488.433C414.19 484.737 409.251 482.66 404.101 482.66Z" fill="#E0E0E0"/>
        <path d="M404.101 482.66H403.485C398.335 482.66 393.395 484.737 389.754 488.433C386.112 492.13 384.066 497.145 384.066 502.373V611.445H423.566V502.373C423.566 499.78 423.063 497.213 422.084 494.818C421.105 492.423 419.67 490.248 417.862 488.416C416.054 486.585 413.908 485.134 411.546 484.146C409.185 483.159 406.655 482.653 404.101 482.66Z" fill="#EBEBEB"/>
        <path d="M375.771 561.32H147.493V611.413H375.771V561.32Z" fill="#E6E6E6"/>
        <path d="M376.087 515.51H155.93C145.031 515.51 136.196 524.479 136.196 535.544V548.023C136.196 559.087 145.031 568.057 155.93 568.057H376.087C386.986 568.057 395.822 559.087 395.822 548.023V535.544C395.822 524.479 386.986 515.51 376.087 515.51Z" fill="#F5F5F5"/>
        <path d="M183.596 515.51H156.294C145.395 515.51 136.559 524.479 136.559 535.544V548.023C136.559 559.087 145.395 568.057 156.294 568.057H183.596C194.495 568.057 203.33 559.087 203.33 548.023V535.544C203.33 524.479 194.495 515.51 183.596 515.51Z" fill="#E0E0E0"/>
        <path d="M153.355 482.66H123.588C118.438 482.66 113.498 484.737 109.857 488.433C106.215 492.13 104.169 497.145 104.169 502.373V611.445H172.757V502.373C172.759 499.785 172.259 497.222 171.285 494.83C170.311 492.439 168.882 490.265 167.08 488.434C165.278 486.604 163.138 485.151 160.783 484.16C158.428 483.17 155.904 482.66 153.355 482.66Z" fill="#E0E0E0"/>
        <path d="M153.355 482.66H152.723C147.573 482.66 142.634 484.737 138.992 488.433C135.35 492.13 133.305 497.145 133.305 502.373V611.445H172.805V502.373C172.807 499.781 172.305 497.214 171.328 494.819C170.351 492.424 168.918 490.249 167.111 488.417C165.304 486.586 163.158 485.135 160.798 484.147C158.437 483.159 155.908 482.653 153.355 482.66Z" fill="#F0F0F0"/>
        <path d="M122.197 236.622V280.78H183.754V236.622H122.197Z" fill="#E0E0E0"/>
        <path d="M125.089 239.509V277.909H180.863V239.509H125.089Z" fill="#EBEBEB"/>
        <path d="M143.543 181.14V225.298H205.1V181.14H143.543Z" fill="#E0E0E0"/>
        <path d="M146.434 184.027V222.427H202.208V184.027H146.434Z" fill="#EBEBEB"/>
        <path d="M234.598 236.622H195.43V292.89H234.598V236.622Z" fill="#E0E0E0"/>
        <path d="M232.039 239.269H197.974V290.244H232.039V239.269Z" fill="#EBEBEB"/>
        <path d="M256.876 162.806H213.379V225.298H256.876V162.806Z" fill="#E0E0E0"/>
        <path d="M254.032 165.741H216.207V222.363H254.032V165.741Z" fill="#EBEBEB"/>
        <path d="M296.092 271.204V236.622H247.886V271.204H296.092Z" fill="#E0E0E0"/>
        <path d="M293.833 268.943V238.868H250.161V268.943H293.833Z" fill="#EBEBEB"/>
        <path d="M463.73 368.808H673.712V148.546L463.73 148.546V368.808Z" fill="#EBEBEB"/>
        <path d="M456.383 368.808H666.365V148.546L456.383 148.546V368.808Z" fill="#F0F0F0"/>
        <path d="M661.072 153.775H461.96V363.691H661.072V153.775Z" fill="#E0E0E0"/>
        <path d="M661.072 153.775H466.937V363.691H661.072V153.775Z" fill="#F5F5F5"/>
        <path d="M562.006 363.691H565.94V153.775H562.006V363.691Z" fill="#EBEBEB"/>
        <path d="M539.38 153.775L484.049 363.691H478.74L534.072 153.775H539.38Z" fill="white"/>
        <path d="M560.3 367.717H563.681V150.535H560.3V367.717Z" fill="#F0F0F0"/>
        <path d="M519.299 153.775L466.937 352.431V243.231L490.511 153.775H519.299Z" fill="white"/>
        <path d="M661.072 172.815V192.961L616.074 363.691H610.765L661.072 172.815Z" fill="white"/>
        <path d="M661.072 213.508V322.693L650.281 363.691H621.509L661.072 213.508Z" fill="white"/>
        <path d="M530.185 565.378L679.511 561.304C680.445 561.276 681.376 561.436 682.249 561.774C683.122 562.113 683.921 562.623 684.599 563.276C685.277 563.929 685.822 564.712 686.201 565.579C686.581 566.446 686.788 567.381 686.81 568.329L687.505 594.41L687.869 608.237L688.011 613.594L682.56 608.381L531.401 612.503C529.515 612.551 527.687 611.839 526.316 610.522C524.946 609.206 524.144 607.393 524.086 605.478L523.233 572.788C523.208 571.838 523.368 570.892 523.704 570.004C524.04 569.116 524.546 568.305 525.192 567.616C525.838 566.928 526.611 566.375 527.468 565.991C528.325 565.607 529.248 565.399 530.185 565.378Z" fill="#E0E0E0"/>
        <path d="M498.158 495.043L646.899 509.174C647.83 509.26 648.734 509.532 649.56 509.975C650.387 510.417 651.119 511.021 651.715 511.751C652.311 512.481 652.759 513.324 653.034 514.23C653.308 515.137 653.403 516.089 653.314 517.033L650.96 543.018L649.68 556.781L649.19 562.122L644.45 556.283L493.876 541.976C491.998 541.793 490.267 540.861 489.065 539.384C487.862 537.908 487.286 536.007 487.462 534.1L490.464 501.555C490.636 499.656 491.543 497.905 492.985 496.684C494.428 495.464 496.288 494.873 498.158 495.043Z" fill="#EBEBEB"/>
        <path d="M528.621 474.223L677.251 458.953C679.121 458.759 680.991 459.326 682.45 460.528C683.91 461.731 684.841 463.471 685.041 465.369L687.632 491.321L689.006 505.084L689.544 510.425L683.745 505.613L533.297 521.075C531.427 521.269 529.558 520.702 528.098 519.5C526.639 518.297 525.707 516.557 525.508 514.659L522.269 482.13C522.079 480.227 522.642 478.325 523.833 476.842C525.024 475.359 526.746 474.417 528.621 474.223Z" fill="#E0E0E0"/>
        <path d="M395 685.806C564.19 685.806 701.346 677.677 701.346 667.649C701.346 657.621 564.19 649.492 395 649.492C225.81 649.492 88.6538 657.621 88.6538 667.649C88.6538 677.677 225.81 685.806 395 685.806Z" fill="#F5F5F5"/>
        <path d="M394.558 187.315V662.853C394.558 663.775 394.377 664.688 394.027 665.539C393.676 666.39 393.163 667.162 392.516 667.81C391.869 668.457 391.102 668.968 390.259 669.312C389.416 669.656 388.514 669.827 387.606 669.814H169.566C168.757 669.826 167.955 669.673 167.206 669.364C166.457 669.055 165.777 668.598 165.205 668.018C164.605 667.42 164.131 666.706 163.81 665.918C163.49 665.129 163.33 664.283 163.34 663.43V186.738C163.313 186 163.429 185.265 163.682 184.573C163.934 183.881 164.319 183.246 164.814 182.706C165.308 182.165 165.903 181.728 166.564 181.421C167.225 181.114 167.939 180.942 168.665 180.915H230.68C231.545 180.932 232.385 181.209 233.094 181.712C233.803 182.214 234.348 182.92 234.662 183.738L237.237 190.154C237.551 190.971 238.097 191.674 238.806 192.174C239.516 192.674 240.355 192.948 241.219 192.961H317.059C317.917 192.956 318.755 192.691 319.464 192.199C320.174 191.707 320.722 191.012 321.04 190.202L323.616 183.786C323.926 182.965 324.471 182.256 325.18 181.751C325.889 181.245 326.731 180.965 327.597 180.947H388.617C390.218 181.045 391.723 181.757 392.826 182.94C393.929 184.122 394.548 185.686 394.558 187.315Z" fill="url(#paint0_linear_18_3)"/>
        <path opacity="0.6" d="M192.128 200.067H195.351V195.704H192.128V200.067Z" fill="white"/>
        <path opacity="0.6" d="M186.882 200.067H190.106V194.26H186.882V200.067Z" fill="white"/>
        <path opacity="0.6" d="M181.637 200.067H184.86V192.801H181.637V200.067Z" fill="white"/>
        <path opacity="0.6" d="M176.375 200.067H179.599V191.341H176.375V200.067Z" fill="white"/>
        <path opacity="0.6" d="M365.043 194.116H363.131V198.479H365.043V194.116Z" fill="white"/>
        <path opacity="0.6" d="M366.355 200.051H381.57V192.512H366.355V200.051Z" fill="white"/>
        <g opacity="0.1">
        <path d="M267.383 192.945L200.85 445.992L200.107 448.831L198.906 453.403L198.148 456.258L163.388 588.395V428.477L228.5 180.867H230.68C231.546 180.887 232.387 181.168 233.095 181.673C233.804 182.178 234.349 182.886 234.662 183.706L237.221 190.122C237.537 190.937 238.084 191.639 238.792 192.139C239.501 192.639 240.34 192.914 241.203 192.929L267.383 192.945Z" fill="white"/>
        <path d="M394.558 322.179V482.098L353.051 639.932L352.04 643.765L345.198 669.798H303.155L309.996 643.781L311.086 639.595L316.585 618.695L317.675 614.508L319.887 606.152L320.993 601.965L323.173 593.592L324.279 589.422L358.881 457.862V457.846L360.477 451.767V451.751L394.558 322.179Z" fill="white"/>
        <path d="M290.183 192.945L224.044 444.42L223.649 445.928L220.031 459.706L219.636 461.198L165.252 668.114C164.658 667.51 164.189 666.792 163.871 666.001C163.554 665.211 163.395 664.364 163.404 663.511V639.098L210.14 461.198L210.551 459.706L214.169 445.928L214.564 444.42L280.687 192.945H290.183Z" fill="white"/>
        </g>
        <path opacity="0.4" d="M394.542 211.904H163.388V648.754H394.542V211.904Z" fill="white"/>
        <path opacity="0.3" d="M394.542 518.814H163.388V648.754H394.542V518.814Z" fill="black"/>
        <path d="M338.073 484.713H187.862C182.417 484.713 178.003 489.194 178.003 494.722C178.003 500.249 182.417 504.731 187.862 504.731H338.073C343.518 504.731 347.932 500.249 347.932 494.722C347.932 489.194 343.518 484.713 338.073 484.713Z" fill="white"/>
        <path d="M371.948 481.793H371.932C364.899 481.793 359.197 487.582 359.197 494.722V494.738C359.197 501.878 364.899 507.666 371.932 507.666H371.948C378.981 507.666 384.683 501.878 384.683 494.738V494.722C384.683 487.582 378.981 481.793 371.948 481.793Z" fill="url(#paint1_linear_18_3)"/>
        <path d="M334.502 280.892H185.113C184.179 280.89 183.253 281.076 182.389 281.438C181.526 281.8 180.741 282.331 180.081 283.002C179.42 283.673 178.896 284.469 178.54 285.346C178.183 286.222 178.001 287.162 178.003 288.11V333.391L183.28 328.018H334.502C335.436 328.02 336.362 327.835 337.225 327.473C338.089 327.111 338.873 326.579 339.534 325.909C340.195 325.238 340.718 324.441 341.075 323.565C341.431 322.688 341.614 321.748 341.612 320.8V288.11C341.614 287.162 341.431 286.222 341.075 285.346C340.718 284.469 340.195 283.673 339.534 283.002C338.873 282.331 338.089 281.8 337.225 281.438C336.362 281.076 335.436 280.89 334.502 280.892Z" fill="white"/>
        <path opacity="0.3" d="M325.812 291.784H194.34C193.766 291.784 193.215 292.015 192.809 292.427C192.403 292.839 192.175 293.398 192.175 293.981C192.173 294.271 192.228 294.558 192.336 294.827C192.443 295.095 192.603 295.34 192.804 295.545C193.005 295.751 193.244 295.914 193.508 296.026C193.772 296.137 194.054 296.195 194.34 296.195H325.843C326.422 296.195 326.976 295.961 327.385 295.546C327.794 295.131 328.024 294.568 328.024 293.981C328.024 293.688 327.966 293.399 327.855 293.129C327.743 292.859 327.579 292.615 327.373 292.41C327.167 292.205 326.923 292.044 326.655 291.937C326.387 291.829 326.1 291.777 325.812 291.784Z" fill="black"/>
        <path opacity="0.3" d="M325.812 302.322H194.34C194.054 302.322 193.772 302.379 193.508 302.491C193.244 302.602 193.005 302.765 192.804 302.971C192.603 303.177 192.443 303.421 192.336 303.69C192.228 303.958 192.173 304.246 192.175 304.535C192.175 305.118 192.403 305.677 192.809 306.089C193.215 306.501 193.766 306.733 194.34 306.733H325.843C326.129 306.735 326.412 306.68 326.677 306.57C326.941 306.461 327.182 306.299 327.384 306.095C327.587 305.891 327.748 305.648 327.858 305.38C327.967 305.112 328.024 304.825 328.024 304.535C328.024 304.242 327.966 303.952 327.855 303.681C327.743 303.41 327.58 303.165 327.374 302.959C327.168 302.753 326.924 302.59 326.656 302.481C326.388 302.372 326.101 302.318 325.812 302.322Z" fill="black"/>
        <path opacity="0.3" d="M278.412 312.876H194.34C193.766 312.876 193.215 313.108 192.809 313.52C192.403 313.932 192.175 314.491 192.175 315.074C192.173 315.364 192.228 315.651 192.336 315.92C192.443 316.188 192.603 316.432 192.804 316.638C193.005 316.844 193.244 317.007 193.508 317.118C193.772 317.23 194.054 317.287 194.34 317.287H278.443C279.022 317.287 279.576 317.054 279.985 316.639C280.394 316.224 280.624 315.661 280.624 315.074C280.624 314.781 280.566 314.491 280.455 314.221C280.343 313.952 280.179 313.707 279.973 313.503C279.767 313.298 279.523 313.137 279.255 313.029C278.987 312.922 278.7 312.87 278.412 312.876Z" fill="black"/>
        <g opacity="0.3">
        <path d="M289.598 313.406C289.598 312.732 289.061 312.267 288.129 312.267C287.777 312.242 287.425 312.306 287.103 312.451C286.78 312.596 286.498 312.819 286.28 313.101L285.538 312.555C285.858 312.157 286.267 311.843 286.732 311.64C287.197 311.436 287.703 311.348 288.208 311.385C289.677 311.385 290.641 312.074 290.641 313.245C290.641 314.994 288.824 315.106 288.824 316.453H287.781C287.781 314.833 289.598 314.593 289.598 313.406ZM287.639 318.218C287.639 318.128 287.657 318.04 287.691 317.958C287.726 317.876 287.776 317.802 287.84 317.741C287.903 317.679 287.979 317.632 288.061 317.6C288.143 317.569 288.231 317.556 288.318 317.56C288.404 317.558 288.489 317.573 288.569 317.605C288.648 317.638 288.721 317.686 288.781 317.748C288.842 317.809 288.89 317.883 288.921 317.963C288.953 318.044 288.968 318.131 288.966 318.218C288.968 318.305 288.953 318.393 288.921 318.474C288.89 318.556 288.843 318.63 288.782 318.693C288.722 318.756 288.65 318.806 288.57 318.84C288.49 318.874 288.405 318.891 288.318 318.891C288.226 318.894 288.134 318.876 288.048 318.841C287.962 318.805 287.885 318.751 287.821 318.683C287.757 318.615 287.708 318.534 287.676 318.446C287.645 318.357 287.632 318.263 287.639 318.169V318.218Z" fill="black"/>
        </g>
        <g opacity="0.7">
        <path opacity="0.7" d="M202.477 531.87H188.099C186.659 531.87 185.492 533.055 185.492 534.517V549.113C185.492 550.575 186.659 551.76 188.099 551.76H202.477C203.917 551.76 205.084 550.575 205.084 549.113V534.517C205.084 533.055 203.917 531.87 202.477 531.87Z" fill="white"/>
        <path opacity="0.7" d="M226.461 531.87H212.083C210.644 531.87 209.476 533.055 209.476 534.517V549.113C209.476 550.575 210.644 551.76 212.083 551.76H226.461C227.901 551.76 229.068 550.575 229.068 549.113V534.517C229.068 533.055 227.901 531.87 226.461 531.87Z" fill="white"/>
        <path opacity="0.7" d="M250.43 531.87H236.052C234.612 531.87 233.445 533.055 233.445 534.517V549.113C233.445 550.575 234.612 551.76 236.052 551.76H250.43C251.87 551.76 253.037 550.575 253.037 549.113V534.517C253.037 533.055 251.87 531.87 250.43 531.87Z" fill="white"/>
        <path opacity="0.7" d="M274.414 531.87H260.036C258.597 531.87 257.429 533.055 257.429 534.517V549.113C257.429 550.575 258.597 551.76 260.036 551.76H274.414C275.854 551.76 277.021 550.575 277.021 549.113V534.517C277.021 533.055 275.854 531.87 274.414 531.87Z" fill="white"/>
        <path opacity="0.7" d="M298.399 531.87H284.021C282.581 531.87 281.414 533.055 281.414 534.517V549.113C281.414 550.575 282.581 551.76 284.021 551.76H298.399C299.839 551.76 301.006 550.575 301.006 549.113V534.517C301.006 533.055 299.839 531.87 298.399 531.87Z" fill="white"/>
        <path opacity="0.7" d="M322.367 531.87H307.989C306.55 531.87 305.382 533.055 305.382 534.517V549.113C305.382 550.575 306.55 551.76 307.989 551.76H322.367C323.807 551.76 324.974 550.575 324.974 549.113V534.517C324.974 533.055 323.807 531.87 322.367 531.87Z" fill="white"/>
        <path opacity="0.7" d="M346.352 531.87H331.974C330.534 531.87 329.367 533.055 329.367 534.517V549.113C329.367 550.575 330.534 551.76 331.974 551.76H346.352C347.792 551.76 348.959 550.575 348.959 549.113V534.517C348.959 533.055 347.792 531.87 346.352 531.87Z" fill="white"/>
        <path opacity="0.7" d="M370.32 531.87H355.942C354.503 531.87 353.335 533.055 353.335 534.517V549.113C353.335 550.575 354.503 551.76 355.942 551.76H370.32C371.76 551.76 372.927 550.575 372.927 549.113V534.517C372.927 533.055 371.76 531.87 370.32 531.87Z" fill="white"/>
        <path opacity="0.7" d="M214.469 558.064H200.091C198.651 558.064 197.484 559.249 197.484 560.71V575.307C197.484 576.768 198.651 577.953 200.091 577.953H214.469C215.909 577.953 217.076 576.768 217.076 575.307V560.71C217.076 559.249 215.909 558.064 214.469 558.064Z" fill="white"/>
        <path opacity="0.7" d="M238.454 558.064H224.076C222.636 558.064 221.469 559.249 221.469 560.71V575.307C221.469 576.768 222.636 577.953 224.076 577.953H238.454C239.893 577.953 241.061 576.768 241.061 575.307V560.71C241.061 559.249 239.893 558.064 238.454 558.064Z" fill="white"/>
        <path opacity="0.7" d="M262.422 558.064H248.044C246.604 558.064 245.437 559.249 245.437 560.71V575.307C245.437 576.768 246.604 577.953 248.044 577.953H262.422C263.862 577.953 265.029 576.768 265.029 575.307V560.71C265.029 559.249 263.862 558.064 262.422 558.064Z" fill="white"/>
        <path opacity="0.7" d="M286.407 558.064H272.029C270.589 558.064 269.422 559.249 269.422 560.71V575.307C269.422 576.768 270.589 577.953 272.029 577.953H286.407C287.846 577.953 289.014 576.768 289.014 575.307V560.71C289.014 559.249 287.846 558.064 286.407 558.064Z" fill="white"/>
        <path opacity="0.7" d="M310.375 558.064H295.997C294.557 558.064 293.39 559.249 293.39 560.71V575.307C293.39 576.768 294.557 577.953 295.997 577.953H310.375C311.815 577.953 312.982 576.768 312.982 575.307V560.71C312.982 559.249 311.815 558.064 310.375 558.064Z" fill="white"/>
        <path opacity="0.7" d="M334.36 558.064H319.982C318.542 558.064 317.375 559.249 317.375 560.71V575.307C317.375 576.768 318.542 577.953 319.982 577.953H334.36C335.799 577.953 336.967 576.768 336.967 575.307V560.71C336.967 559.249 335.799 558.064 334.36 558.064Z" fill="white"/>
        <path opacity="0.7" d="M358.328 558.064H343.95C342.51 558.064 341.343 559.249 341.343 560.71V575.307C341.343 576.768 342.51 577.953 343.95 577.953H358.328C359.768 577.953 360.935 576.768 360.935 575.307V560.71C360.935 559.249 359.768 558.064 358.328 558.064Z" fill="white"/>
        <path opacity="0.7" d="M226.461 584.257H212.083C210.644 584.257 209.476 585.442 209.476 586.904V601.5C209.476 602.962 210.644 604.147 212.083 604.147H226.461C227.901 604.147 229.068 602.962 229.068 601.5V586.904C229.068 585.442 227.901 584.257 226.461 584.257Z" fill="white"/>
        <path opacity="0.7" d="M250.43 584.257H236.052C234.612 584.257 233.445 585.442 233.445 586.904V601.5C233.445 602.962 234.612 604.147 236.052 604.147H250.43C251.87 604.147 253.037 602.962 253.037 601.5V586.904C253.037 585.442 251.87 584.257 250.43 584.257Z" fill="white"/>
        <path opacity="0.7" d="M274.414 584.257H260.036C258.597 584.257 257.429 585.442 257.429 586.904V601.5C257.429 602.962 258.597 604.147 260.036 604.147H274.414C275.854 604.147 277.021 602.962 277.021 601.5V586.904C277.021 585.442 275.854 584.257 274.414 584.257Z" fill="white"/>
        <path opacity="0.7" d="M298.399 584.257H284.021C282.581 584.257 281.414 585.442 281.414 586.904V601.5C281.414 602.962 282.581 604.147 284.021 604.147H298.399C299.839 604.147 301.006 602.962 301.006 601.5V586.904C301.006 585.442 299.839 584.257 298.399 584.257Z" fill="white"/>
        <path opacity="0.7" d="M322.367 584.257H307.989C306.55 584.257 305.382 585.442 305.382 586.904V601.5C305.382 602.962 306.55 604.147 307.989 604.147H322.367C323.807 604.147 324.974 602.962 324.974 601.5V586.904C324.974 585.442 323.807 584.257 322.367 584.257Z" fill="white"/>
        <path opacity="0.7" d="M346.352 584.257H331.974C330.534 584.257 329.367 585.442 329.367 586.904V601.5C329.367 602.962 330.534 604.147 331.974 604.147H346.352C347.792 604.147 348.959 602.962 348.959 601.5V586.904C348.959 585.442 347.792 584.257 346.352 584.257Z" fill="white"/>
        <path opacity="0.7" d="M202.477 610.466H188.099C186.659 610.466 185.492 611.651 185.492 613.113V627.709C185.492 629.171 186.659 630.356 188.099 630.356H202.477C203.917 630.356 205.084 629.171 205.084 627.709V613.113C205.084 611.651 203.917 610.466 202.477 610.466Z" fill="white"/>
        <path opacity="0.7" d="M226.461 610.466H212.083C210.644 610.466 209.476 611.651 209.476 613.113V627.709C209.476 629.171 210.644 630.356 212.083 630.356H226.461C227.901 630.356 229.068 629.171 229.068 627.709V613.113C229.068 611.651 227.901 610.466 226.461 610.466Z" fill="white"/>
        <path opacity="0.7" d="M322.32 610.466H236.068C235.724 610.464 235.383 610.531 235.065 610.663C234.747 610.795 234.458 610.99 234.214 611.236C233.97 611.482 233.777 611.774 233.645 612.096C233.513 612.419 233.445 612.764 233.445 613.113V627.693C233.445 628.043 233.513 628.389 233.645 628.712C233.776 629.035 233.97 629.329 234.213 629.576C234.457 629.823 234.746 630.019 235.064 630.153C235.382 630.287 235.723 630.356 236.068 630.356H322.32C322.664 630.356 323.004 630.287 323.321 630.153C323.639 630.019 323.927 629.823 324.169 629.575C324.411 629.328 324.603 629.034 324.733 628.711C324.863 628.388 324.929 628.042 324.927 627.693V613.113C324.927 612.411 324.652 611.738 324.163 611.242C323.675 610.745 323.011 610.466 322.32 610.466Z" fill="white"/>
        <path opacity="0.7" d="M346.352 610.466H331.974C330.534 610.466 329.367 611.651 329.367 613.113V627.709C329.367 629.171 330.534 630.356 331.974 630.356H346.352C347.792 630.356 348.959 629.171 348.959 627.709V613.113C348.959 611.651 347.792 610.466 346.352 610.466Z" fill="white"/>
        <path opacity="0.7" d="M370.32 610.466H355.942C354.503 610.466 353.335 611.651 353.335 613.113V627.709C353.335 629.171 354.503 630.356 355.942 630.356H370.32C371.76 630.356 372.927 629.171 372.927 627.709V613.113C372.927 611.651 371.76 610.466 370.32 610.466Z" fill="white"/>
        </g>
        <path opacity="0.3" d="M394.542 211.904H163.388V257.314H394.542V211.904Z" fill="black"/>
        <path opacity="0.6" d="M188.494 221.673C186.82 221.673 185.163 222.008 183.617 222.658C182.071 223.309 180.666 224.263 179.484 225.465C178.301 226.667 177.363 228.094 176.724 229.665C176.085 231.235 175.757 232.918 175.759 234.617C175.759 238.046 177.101 241.334 179.489 243.759C181.877 246.183 185.116 247.545 188.494 247.545C190.168 247.547 191.825 247.215 193.372 246.566C194.919 245.917 196.325 244.965 197.509 243.764C198.693 242.564 199.633 241.138 200.274 239.568C200.915 237.999 201.245 236.316 201.245 234.617C201.245 231.184 199.901 227.892 197.51 225.464C195.119 223.037 191.876 221.673 188.494 221.673Z" fill="white"/>
        <path opacity="0.6" d="M261.616 232.243H216.46C215.685 232.243 214.941 231.931 214.393 231.374C213.845 230.818 213.537 230.063 213.537 229.276C213.537 228.489 213.845 227.734 214.393 227.177C214.941 226.621 215.685 226.308 216.46 226.308H261.601C261.984 226.308 262.363 226.385 262.717 226.535C263.071 226.684 263.392 226.903 263.662 227.178C263.932 227.454 264.146 227.781 264.291 228.141C264.436 228.501 264.51 228.887 264.508 229.276C264.51 229.663 264.437 230.048 264.293 230.406C264.148 230.765 263.936 231.092 263.667 231.367C263.399 231.643 263.08 231.862 262.728 232.012C262.376 232.163 261.998 232.241 261.616 232.243Z" fill="white"/>
        <path opacity="0.6" d="M290.436 243.664H215.022C214.632 243.664 214.259 243.506 213.983 243.227C213.708 242.947 213.553 242.568 213.553 242.172C213.557 241.779 213.714 241.404 213.989 241.127C214.264 240.851 214.635 240.696 215.022 240.696H290.436C290.823 240.696 291.194 240.851 291.469 241.127C291.744 241.404 291.901 241.779 291.905 242.172C291.905 242.568 291.75 242.947 291.475 243.227C291.199 243.506 290.825 243.664 290.436 243.664Z" fill="white"/>
        <path opacity="0.6" d="M382.202 227.848H365.233C364.847 227.848 364.478 227.693 364.205 227.416C363.932 227.139 363.779 226.764 363.779 226.373C363.779 225.98 363.932 225.603 364.204 225.323C364.476 225.044 364.846 224.885 365.233 224.881H382.202C382.589 224.885 382.959 225.044 383.231 225.323C383.503 225.603 383.656 225.98 383.656 226.373C383.656 226.764 383.502 227.139 383.23 227.416C382.957 227.693 382.587 227.848 382.202 227.848Z" fill="white"/>
        <path opacity="0.6" d="M382.202 243.888H365.233C364.847 243.888 364.478 243.733 364.205 243.456C363.932 243.179 363.779 242.804 363.779 242.413C363.779 242.02 363.932 241.643 364.204 241.363C364.476 241.084 364.846 240.925 365.233 240.921H382.202C382.589 240.925 382.959 241.084 383.231 241.363C383.503 241.643 383.656 242.02 383.656 242.413C383.656 242.804 383.502 243.179 383.23 243.456C382.957 243.733 382.587 243.888 382.202 243.888Z" fill="white"/>
        <path opacity="0.6" d="M382.202 235.868H365.233C364.847 235.868 364.478 235.713 364.205 235.436C363.932 235.159 363.779 234.784 363.779 234.393C363.779 234 363.932 233.623 364.204 233.343C364.476 233.064 364.846 232.905 365.233 232.901H382.202C382.589 232.905 382.959 233.064 383.231 233.343C383.503 233.623 383.656 234 383.656 234.393C383.656 234.784 383.502 235.159 383.23 235.436C382.957 235.713 382.587 235.868 382.202 235.868Z" fill="white"/>
        <path d="M498.996 385.409L496.531 387.366C493.703 389.531 488.82 393.189 483.527 396.701C476.18 401.513 468.059 406.085 463.698 405.812C457.631 405.411 430.013 385.778 430.013 385.778C430.013 385.778 416.298 393.205 413.581 391.087C410.863 388.97 407.261 381.624 407.071 380.709C406.882 379.795 420.659 372.689 424.167 372.689C427.674 372.689 432.92 380.148 432.92 380.148C432.92 380.148 461.139 395.835 464.236 395.45C467.332 395.065 481.71 381.944 487.936 376.042L498.996 385.409Z" fill="#E4897B"/>
        <path opacity="0.2" d="M496.531 387.366C493.703 389.531 488.82 393.189 483.527 396.701C484.302 389.884 491.791 381.848 491.791 381.848L496.531 387.366Z" fill="black"/>
        <path d="M538.906 299.002C538.906 299.002 539.981 294.879 537.326 287.774L539.096 286.795C539.721 288.495 540.249 290.23 540.676 291.992C541.862 289.551 542.494 286.871 542.525 284.149H545.1C545.064 286.849 544.741 289.538 544.136 292.169C544.136 292.169 547.296 286.972 552.147 285.993V289.121C550.781 289.707 549.521 290.521 548.418 291.527C550.519 290.822 552.75 290.608 554.943 290.901V294.815C554.943 294.815 551.088 295.601 549.824 297.366C550.91 297.259 552.005 297.388 553.038 297.745C554.071 298.102 555.016 298.678 555.812 299.435L554.375 301.68C554.375 301.68 550.014 300.397 547.122 301.68C549.015 302.015 550.711 303.071 551.862 304.632L550.693 306.091C548.02 304.192 544.756 303.348 541.513 303.717C536.6 304.6 538.906 299.002 538.906 299.002Z" fill="#263238"/>
        <path d="M523.833 310.967L538.59 299.098C538.59 299.098 542.335 301.744 543.172 304.231L528.779 318.346L523.833 310.967Z" fill="url(#paint2_linear_18_3)"/>
        <path d="M490.464 336.439C490.464 336.439 481.284 321.105 493.892 309.893C506.501 298.681 524.386 302.145 532.128 310.967C541.513 321.634 543.188 345.486 517.734 355.511C488.331 367.107 490.464 336.439 490.464 336.439Z" fill="#263238"/>
        <path d="M603.007 415.981H596.197L577.237 528.935H584.047L603.007 415.981Z" fill="#263238"/>
        <path d="M577.727 415.981H570.917L551.957 528.935H558.767L577.727 415.981Z" fill="#263238"/>
        <path d="M552.447 415.981H545.637L526.677 528.935H533.487L552.447 415.981Z" fill="#263238"/>
        <path opacity="0.4" d="M603.007 415.981H596.197L577.237 528.935H584.047L603.007 415.981Z" fill="white"/>
        <path opacity="0.4" d="M577.727 415.981H570.917L551.957 528.935H558.767L577.727 415.981Z" fill="white"/>
        <path opacity="0.4" d="M552.447 415.981H545.637L526.677 528.935H533.487L552.447 415.981Z" fill="white"/>
        <path d="M544.8 531.87L544.231 556.845L541.64 669.814H533.092L530.517 556.845L529.964 531.87H544.8Z" fill="#263238"/>
        <path opacity="0.4" d="M544.8 531.87L544.231 556.845L541.64 669.814H533.092L530.517 556.845L529.964 531.87H544.8Z" fill="white"/>
        <path opacity="0.2" d="M544.8 531.87L544.231 557.534H530.517L529.964 531.87H544.8Z" fill="black"/>
        <path d="M591.22 669.413L583.336 533.635H570.427L581.472 669.413H591.22Z" fill="#263238"/>
        <path opacity="0.4" d="M581.456 669.413H591.204L584.047 545.825L583.336 533.474H570.427L571.423 545.825L581.456 669.413Z" fill="white"/>
        <path opacity="0.2" d="M570.427 533.474L571.423 546.306H584.047L583.336 533.474H570.427Z" fill="black"/>
        <path d="M493.276 669.413L504.336 533.635H491.427L483.527 669.413H493.276Z" fill="#263238"/>
        <path opacity="0.4" d="M493.308 669.413L503.341 545.825L504.336 533.474H491.427L490.716 545.825L483.543 669.413H493.308Z" fill="white"/>
        <path opacity="0.2" d="M504.336 533.474L503.341 546.306H490.716L491.427 533.474H504.336Z" fill="black"/>
        <path d="M492.407 519.568H582.341C584.436 519.568 586.445 520.413 587.927 521.917C589.408 523.421 590.241 525.461 590.241 527.588V538.318H484.507V527.588C484.507 525.461 485.339 523.421 486.821 521.917C488.302 520.413 490.312 519.568 492.407 519.568Z" fill="url(#paint3_linear_18_3)"/>
        <path d="M591.236 429.022C580.271 422.285 565.055 422.285 550.504 429.022C543.157 432.23 534.467 433.224 530.611 428.509C524.054 421.579 535.62 401 557.645 394.584C573.445 389.981 589.103 389.981 602.454 394.584C621.082 401.096 621.651 421.643 611.413 428.573C605.045 433.224 596.861 432.294 591.236 429.022Z" fill="url(#paint4_linear_18_3)"/>
        <path opacity="0.4" d="M492.407 519.568H582.341C584.436 519.568 586.445 520.413 587.927 521.917C589.408 523.421 590.241 525.461 590.241 527.588V538.318H484.507V527.588C484.507 525.461 485.339 523.421 486.821 521.917C488.302 520.413 490.312 519.568 492.407 519.568Z" fill="black"/>
        <path d="M592.137 528.935H482.627C480.873 528.935 479.451 530.378 479.451 532.159V535.078C479.451 536.859 480.873 538.302 482.627 538.302H592.137C593.891 538.302 595.312 536.859 595.312 535.078V532.159C595.312 530.378 593.891 528.935 592.137 528.935Z" fill="#263238"/>
        <path opacity="0.6" d="M592.137 528.935H482.627C480.873 528.935 479.451 530.378 479.451 532.159V535.078C479.451 536.859 480.873 538.302 482.627 538.302H592.137C593.891 538.302 595.312 536.859 595.312 535.078V532.159C595.312 530.378 593.891 528.935 592.137 528.935Z" fill="white"/>
        <path opacity="0.4" d="M591.236 429.022C580.271 422.285 565.055 422.285 550.504 429.022C543.157 432.23 534.467 433.224 530.611 428.509C524.054 421.579 535.62 401 557.645 394.584C573.445 389.981 589.103 389.981 602.454 394.584C621.082 401.096 621.651 421.643 611.413 428.573C605.045 433.224 596.861 432.294 591.236 429.022Z" fill="black"/>
        <path d="M397.86 612.728L408.256 615.262L413.518 600.698L418.289 587.465L406.455 582.332L401.905 598.404L397.86 612.728Z" fill="#E4897B"/>
        <path d="M410.8 614.557C411.053 613.386 396.754 610.851 396.754 610.851C396.754 610.851 385.978 617.684 377.099 617.636C368.219 617.588 371.695 623.86 381.096 626.153L404.338 631.848C411.005 633.468 408.209 626.683 410.8 614.557Z" fill="#263238"/>
        <path d="M387.305 612.054C387.141 611.82 387.052 611.54 387.052 611.252C387.052 610.965 387.141 610.684 387.305 610.45C387.408 610.259 387.547 610.09 387.715 609.955C387.884 609.82 388.078 609.721 388.285 609.664C389.356 609.509 390.449 609.654 391.445 610.081C390.45 609.183 389.612 608.189 389.754 607.435C389.849 606.938 390.244 606.601 390.971 606.456C391.286 606.382 391.613 606.377 391.929 606.444C392.246 606.51 392.544 606.646 392.804 606.841C394.51 608.125 394.905 611.734 394.921 611.894C394.93 611.952 394.93 612.012 394.921 612.07V612.167L394.81 612.263C394.77 612.279 394.725 612.279 394.684 612.263C393.184 612.601 391.654 612.789 390.118 612.824C389.622 612.896 389.117 612.865 388.634 612.732C388.15 612.6 387.698 612.369 387.305 612.054ZM388.522 610.45C388.411 610.473 388.307 610.521 388.217 610.591C388.126 610.66 388.053 610.749 388.001 610.851C387.732 611.365 387.874 611.557 388.001 611.621C388.522 612.343 391.303 612.183 393.578 611.798C392.014 611.028 389.675 610.065 388.522 610.418V610.45ZM391.224 607.242C391.018 607.242 390.655 607.403 390.608 607.595C390.497 608.253 392.188 609.857 394.147 611.172C393.982 609.797 393.389 608.51 392.456 607.499C392.204 607.311 391.9 607.21 391.587 607.21L391.224 607.242Z" fill="#263238"/>
        <path opacity="0.2" d="M401.905 598.404L413.518 600.698L418.289 587.465L406.455 582.332L401.905 598.404Z" fill="black"/>
        <path d="M402.9 590.721L417.31 596.159C417.31 596.159 455.751 498.587 463.651 498.315C471.551 498.042 551.989 535.511 572.15 514.692C592.31 493.872 563.159 443.731 563.159 443.731C563.159 443.731 514.669 436.705 511.019 447.821C510.121 451.097 509.435 454.43 508.965 457.798C508.412 461.198 508.033 464.053 508.033 464.053C506.532 463.668 457.852 450.916 438.671 464.053C419.206 477.366 402.9 590.721 402.9 590.721Z" fill="url(#paint5_linear_18_3)"/>
        <path d="M485.834 654.352L496.183 651.657L494.192 636.579L492.344 622.753L479.546 623.908L482.864 640.028L485.834 654.352Z" fill="#E4897B"/>
        <path d="M498.98 651.224C498.98 650.037 484.475 651.144 484.475 651.144C484.475 651.144 475.675 660.447 467.095 662.628C458.516 664.81 463.367 670.007 473.036 669.895L496.957 669.59C503.767 669.429 499.406 663.543 498.98 651.224Z" fill="#263238"/>
        <path d="M475.58 654.576C475.357 654.385 475.2 654.126 475.132 653.837C475.064 653.549 475.088 653.246 475.201 652.972C475.255 652.763 475.35 652.567 475.48 652.397C475.61 652.226 475.773 652.083 475.959 651.978C476.984 651.532 478.114 651.393 479.214 651.577C478.029 650.951 476.986 650.181 476.923 649.411C476.923 648.909 477.239 648.492 477.871 648.16C478.163 648.022 478.481 647.95 478.803 647.95C479.125 647.95 479.444 648.022 479.735 648.16C481.71 648.962 482.895 652.363 483.022 652.523C483.038 652.581 483.038 652.642 483.022 652.7C483.009 652.754 482.982 652.804 482.943 652.844H482.848C480.97 653.847 478.932 654.504 476.828 654.785C476.401 654.861 475.961 654.788 475.58 654.576ZM476.354 652.684C476.254 652.735 476.166 652.808 476.095 652.896C476.025 652.985 475.973 653.087 475.943 653.197C475.801 653.774 475.943 653.935 476.054 653.983C476.733 654.544 479.388 653.694 481.505 652.764C479.925 652.411 477.444 652.058 476.402 652.684H476.354ZM478.203 648.914C477.997 649.01 477.681 649.203 477.697 649.411C477.697 650.069 479.814 651.192 481.979 651.978C481.498 650.66 480.612 649.535 479.451 648.77C479.269 648.702 479.076 648.664 478.882 648.658C478.656 648.696 478.44 648.783 478.25 648.914H478.203Z" fill="#263238"/>
        <path opacity="0.2" d="M482.943 640.349L494.271 636.868L492.375 622.897L479.562 623.988L482.943 640.349Z" fill="black"/>
        <path d="M423.314 590.753L402.331 583.503L401.431 592.229L417.341 597.955L423.314 590.753Z" fill="url(#paint6_linear_18_3)"/>
        <path d="M537.374 519.696C537.374 519.696 534.087 519.776 483.227 517.37C483.227 517.37 490.827 581.931 494.982 630.244L479.767 632.874C479.767 632.874 443.427 529.4 443.822 512.959C444.217 496.518 462.782 491.706 462.782 491.706C462.782 491.706 499.027 512.895 537.374 519.696Z" fill="url(#paint7_linear_18_3)"/>
        <path opacity="0.3" d="M537.374 519.696C537.374 519.696 534.087 519.776 483.227 517.37C483.227 517.37 490.827 581.931 494.982 630.244L479.767 632.874C479.767 632.874 443.427 529.4 443.822 512.959C444.217 496.518 462.782 491.706 462.782 491.706C462.782 491.706 499.027 512.895 537.374 519.696Z" fill="black"/>
        <path d="M497.21 625.496L476.307 627.918L480.336 636.227L497.258 634.029L497.21 625.496Z" fill="url(#paint8_linear_18_3)"/>
        <path opacity="0.3" d="M508.081 463.989L536.442 469.972C536.442 469.972 520.768 461.182 509.013 457.717C508.46 461.134 508.081 463.989 508.081 463.989Z" fill="black"/>
        <path d="M562.038 436.015L563.207 443.666L511.067 447.757L509.692 438.694L562.038 436.015Z" fill="#263238"/>
        <path opacity="0.2" d="M562.038 436.015L563.207 443.666L511.067 447.757L509.692 438.694L562.038 436.015Z" fill="black"/>
        <path d="M545.589 448.837L548.167 448.568L546.817 435.244L544.24 435.513L545.589 448.837Z" fill="url(#paint9_linear_18_3)"/>
        <path d="M522.423 447.515L533.839 446.77L533.282 437.982L521.866 438.728L522.423 447.515Z" fill="white"/>
        <path d="M513.614 450.235L516.192 449.966L514.842 436.643L512.265 436.912L513.614 450.235Z" fill="url(#paint10_linear_18_3)"/>
        <path d="M562.038 436.015C562.038 436.015 557.772 392.707 550.393 373.315C549.344 370.596 547.495 368.271 545.097 366.658C542.699 365.045 539.87 364.222 536.995 364.3C520.018 364.675 503.307 368.652 487.936 375.978C487.936 375.978 493.26 408.539 499.264 414.955C505.268 421.371 509.692 438.694 509.692 438.694L562.038 436.015Z" fill="#263238"/>
        <path d="M510.593 369.145C510.798 372.353 509.123 373.219 509.123 373.219C509.065 374.195 509.227 375.172 509.598 376.076C509.969 376.979 510.538 377.784 511.262 378.429C511.987 379.075 512.847 379.543 513.777 379.8C514.707 380.056 515.682 380.093 516.628 379.907C526.298 377.918 528.226 365.696 528.226 365.696C520.847 363.531 518.493 355.751 517.734 351.067C517.522 349.843 517.4 348.605 517.371 347.362L504.936 357.163C506.24 358.936 507.418 360.801 508.46 362.745C509.588 364.71 510.313 366.887 510.593 369.145Z" fill="#E4897B"/>
        <path opacity="0.2" d="M504.936 357.163C506.24 358.936 507.418 360.801 508.46 362.745C509.59 364.715 510.315 366.898 510.593 369.161C518.035 368.246 517.687 351.18 517.687 351.18V351.084C517.475 349.859 517.353 348.621 517.324 347.378L504.936 357.163Z" fill="black"/>
        <path d="M507.622 316.277C494.177 315.667 489.516 322.693 487.92 336.808C485.913 354.34 489.2 367.011 506.185 364.076C529.3 359.954 529.964 317.287 507.622 316.277Z" fill="#E4897B"/>
        <path d="M496.641 336.439C495.475 338.428 494.08 340.27 492.486 341.925C492.853 342.508 493.372 342.978 493.985 343.281C494.599 343.584 495.283 343.708 495.962 343.641L496.641 336.439Z" fill="#DE5753"/>
        <path d="M504.936 335.701C504.857 336.824 505.395 337.77 506.121 337.818C506.848 337.867 507.528 337 507.591 335.878C507.654 334.755 507.133 333.808 506.406 333.76C505.679 333.712 505 334.578 504.936 335.701Z" fill="#263238"/>
        <path d="M506.706 333.841L504.368 332.718C504.368 332.718 505.426 334.851 506.706 333.841Z" fill="#263238"/>
        <path d="M491.222 334.514C491.222 335.637 491.68 336.583 492.423 336.631C493.165 336.68 493.813 335.813 493.892 334.707C493.971 333.6 493.418 332.638 492.691 332.573C491.965 332.509 491.38 333.391 491.222 334.514Z" fill="#263238"/>
        <path d="M492.96 332.654L490.622 331.531C490.622 331.531 491.712 333.632 492.96 332.654Z" fill="#263238"/>
        <path d="M505.6 326.013C506.671 326.03 507.723 326.297 508.676 326.792C509.629 327.287 510.458 327.997 511.098 328.868" stroke="#263238" strokeWidth="0.72468" strokeLinejoin="round" />
        <path d="M495.646 324.762C494.701 324.654 493.744 324.764 492.847 325.083C491.95 325.402 491.135 325.923 490.464 326.606" stroke="#263238" strokeWidth="0.72468" strokeLinejoin="round" />
        <path d="M521.4 339.952C521.4 339.952 515.601 328.451 516.929 319.838C516.929 319.838 490.748 307.567 488.236 334.979C488.236 334.979 486.656 310.47 509.85 310.919C533.045 311.368 531.401 345.774 518.998 355.462C518.919 355.511 524.908 347.154 521.4 339.952Z" fill="#263238"/>
        <path d="M518.998 342.743C519.195 341.169 520 339.739 521.236 338.767C522.471 337.796 524.037 337.362 525.587 337.562C529.742 338.203 531.338 346.977 520.847 348.597C519.378 348.838 518.603 347.33 518.998 342.743Z" fill="#E4897B"/>
        <path d="M504.431 345.486C504.113 347.06 503.242 348.463 501.98 349.434C500.719 350.405 499.153 350.877 497.574 350.763" stroke="#263238" strokeWidth="0.72468" strokeLinejoin="round" />
        <path opacity="0.4" d="M522.269 393.654L515.459 394.921L512.836 423.456H517.734L518.888 414.907L527.815 413.623L533.503 421.242L541.197 419.975L522.269 393.654ZM518.998 410.464L519.788 401.497L524.987 409.517L518.998 410.464Z" fill="white"/>
        <path d="M544.626 367.541C559.431 369.947 545.874 423.151 542.13 428.861C538.385 434.572 504.936 457.733 504.936 457.733C504.936 457.733 503.562 462.866 501.002 462.321C498.443 461.776 481.774 460.717 479.925 458.904C478.076 457.092 483.085 453.29 484.507 452.344C485.929 451.398 502.44 451.157 502.44 451.157C502.44 451.157 529 428.861 531.812 424.739C534.625 420.617 536.694 388.553 537.374 380.004C538.053 371.454 540.739 366.899 544.626 367.541Z" fill="#E4897B"/>
        <path d="M266.641 348.998L415.619 337.626C416.55 337.554 417.486 337.669 418.373 337.965C419.26 338.26 420.08 338.731 420.788 339.349C421.495 339.968 422.076 340.722 422.496 341.568C422.915 342.415 423.167 343.337 423.235 344.283L425.162 370.299L426.189 384.078L426.584 389.435L420.912 384.495L270.18 395.996C269.249 396.068 268.313 395.953 267.426 395.657C266.539 395.361 265.718 394.891 265.011 394.272C264.303 393.654 263.723 392.9 263.303 392.053C262.883 391.207 262.632 390.284 262.564 389.339L260.147 356.73C260.003 354.831 260.605 352.952 261.822 351.503C263.039 350.054 264.771 349.154 266.641 348.998Z" fill="url(#paint11_linear_18_3)"/>
        <path opacity="0.3" d="M407.751 349.143L276.611 359.152C276.035 359.197 275.5 359.472 275.123 359.917C274.747 360.362 274.56 360.941 274.604 361.526C274.648 362.107 274.917 362.648 275.352 363.029C275.787 363.411 276.353 363.602 276.927 363.563L408.067 353.554C408.643 353.509 409.177 353.233 409.554 352.788C409.93 352.343 410.117 351.765 410.073 351.18C410.029 350.598 409.76 350.058 409.325 349.676C408.89 349.295 408.324 349.103 407.751 349.143Z" fill="black"/>
        <path opacity="0.3" d="M408.525 359.665L277.385 369.674C277.1 369.692 276.821 369.768 276.565 369.897C276.309 370.026 276.08 370.205 275.894 370.425C275.707 370.644 275.565 370.9 275.476 371.176C275.388 371.452 275.354 371.743 275.378 372.032C275.423 372.617 275.694 373.16 276.132 373.541C276.571 373.923 277.141 374.113 277.717 374.069L408.857 364.06C409.42 364.003 409.939 363.724 410.302 363.283C410.665 362.843 410.844 362.275 410.8 361.702C410.755 361.129 410.493 360.596 410.069 360.216C409.644 359.836 409.09 359.638 408.525 359.665Z" fill="black"/>
        <path opacity="0.3" d="M362.041 373.732L278.175 380.148C277.599 380.193 277.064 380.468 276.688 380.914C276.311 381.359 276.125 381.937 276.168 382.522C276.213 383.107 276.484 383.65 276.922 384.032C277.361 384.414 277.93 384.603 278.507 384.559L362.373 378.143C362.949 378.098 363.484 377.823 363.86 377.377C364.236 376.932 364.423 376.354 364.38 375.769C364.335 375.184 364.064 374.641 363.626 374.259C363.187 373.877 362.617 373.688 362.041 373.732Z" fill="black"/>
        <defs>
        <linearGradient id="paint0_linear_18_3" x1="163.336" y1="427.885" x2="394.558" y2="427.885" gradientUnits="userSpaceOnUse">
        <stop offset="0.261073" stopColor="#161B62"/>
        <stop offset="1" stopColor="#6935FD"/>
        </linearGradient>
        <linearGradient id="paint1_linear_18_3" x1="359.197" y1="494.863" x2="384.683" y2="494.863" gradientUnits="userSpaceOnUse">
        <stop offset="0.261073" stopColor="#161B62"/>
        <stop offset="1" stopColor="#6935FD"/>
        </linearGradient>
        <linearGradient id="paint2_linear_18_3" x1="523.833" y1="308.821" x2="543.172" y2="308.821" gradientUnits="userSpaceOnUse">
        <stop offset="0.261073" stopColor="#161B62"/>
        <stop offset="1" stopColor="#6935FD"/>
        </linearGradient>
        <linearGradient id="paint3_linear_18_3" x1="484.507" y1="529.04" x2="590.241" y2="529.04" gradientUnits="userSpaceOnUse">
        <stop offset="0.261073" stopColor="#161B62"/>
        <stop offset="1" stopColor="#6935FD"/>
        </linearGradient>
        <linearGradient id="paint4_linear_18_3" x1="528.784" y1="411.661" x2="618.057" y2="411.661" gradientUnits="userSpaceOnUse">
        <stop offset="0.261073" stopColor="#161B62"/>
        <stop offset="1" stopColor="#6935FD"/>
        </linearGradient>
        <linearGradient id="paint5_linear_18_3" x1="402.9" y1="519.647" x2="579.254" y2="519.647" gradientUnits="userSpaceOnUse">
        <stop offset="0.261073" stopColor="#161B62"/>
        <stop offset="1" stopColor="#6935FD"/>
        </linearGradient>
        <linearGradient id="paint6_linear_18_3" x1="401.431" y1="590.804" x2="423.314" y2="590.804" gradientUnits="userSpaceOnUse">
        <stop offset="0.261073" stopColor="#161B62"/>
        <stop offset="1" stopColor="#6935FD"/>
        </linearGradient>
        <linearGradient id="paint7_linear_18_3" x1="443.819" y1="563.018" x2="537.374" y2="563.018" gradientUnits="userSpaceOnUse">
        <stop offset="0.261073" stopColor="#161B62"/>
        <stop offset="1" stopColor="#6935FD"/>
        </linearGradient>
        <linearGradient id="paint8_linear_18_3" x1="476.307" y1="630.917" x2="497.258" y2="630.917" gradientUnits="userSpaceOnUse">
        <stop offset="0.261073" stopColor="#161B62"/>
        <stop offset="1" stopColor="#6935FD"/>
        </linearGradient>
        <linearGradient id="paint9_linear_18_3" x1="547.485" y1="441.837" x2="544.907" y2="442.098" gradientUnits="userSpaceOnUse">
        <stop offset="0.261073" stopColor="#161B62"/>
        <stop offset="1" stopColor="#6935FD"/>
        </linearGradient>
        <linearGradient id="paint10_linear_18_3" x1="515.51" y1="443.235" x2="512.932" y2="443.497" gradientUnits="userSpaceOnUse">
        <stop offset="0.261073" stopColor="#161B62"/>
        <stop offset="1" stopColor="#6935FD"/>
        </linearGradient>
        <linearGradient id="paint11_linear_18_3" x1="260.126" y1="367.112" x2="426.584" y2="367.112" gradientUnits="userSpaceOnUse">
        <stop offset="0.261073" stopColor="#161B62"/>
        <stop offset="1" stopColor="#6935FD"/>
        </linearGradient>
        </defs>
      </svg>
    </>
  )
}

export default HeroPagePic