import NavBar from "../components/NavBar";
import { Row, Col, Container, Button } from "react-bootstrap";
import Sidebar from "../components/Sidebar";
import InputBox from "../components/InputBox";
import DialogueCard from "../components/DialogueCard";
import { useEffect, useState, useRef } from "react";
import { PiSidebarSimpleFill } from "react-icons/pi";
import "./styles/Chat.scss";
import ModalErrorOccured from "../components/ModalErrorOccured";
import { useNavigate } from "react-router-dom";
import {PulseLoader, HashLoader} from 'react-spinners'
import { useSetAuth, useAuth } from "../components/AuthContext";

const Chat = ({ BASE_URL }) => {
  // const BASE_URL = "http://localhost:8000/chat/";
  // const BASE_URL = "http://192.168.1.52:8000/chat/";
  // const BASE_URL = "https://zback.liara.run/chat/";
  BASE_URL = BASE_URL + 'chat/'
  const [sessions, setSessions] = useState([]);
  const [messages, setMessages] = useState([]);
  const [chosenSession, setChosenSession] = useState("");
  const [showSidebar, setShowSidebar] = useState(true);
  const [userMessage, setUserMessage] = useState("");
  const [errorOccured, setErrorOccured] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [loadingSessions, setLoadingSessions] = useState(true);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const SetAuth = useSetAuth();
  const isAuthenticated = useAuth();

  const messagesRef = useRef(null);

  useEffect(() => {
    messagesRef.current?.scrollIntoView(false)
  }, [messages])
  const navigate = useNavigate();
  useEffect(() => {
    const getToken = window.localStorage.getItem("token");
    const FetchSessions = async () => {
      try {
        setLoadingSessions(true)
        const getToken = window.localStorage.getItem("token");
        const res = await fetch(BASE_URL + "list_sessions/", {
          headers: {
            Authorization: `Token ${getToken}`,
          },
        });
        if (res.ok) {
          const fetched_session = await res.json();
          // console.log( `fetched data: ${JSON.stringify(fetched_session)}`)
          setLoadingSessions(false)
          setSessions(fetched_session);
        } else {
          window.localStorage.removeItem("token");
          SetAuth(false);
          localStorage.setItem("authenticated", false);
          navigate("/auth");
        }
      } catch (error) {
        setErrorOccured(true);
      }
    };
    if (getToken === null && !isAuthenticated) {
      navigate("/auth");
    }
    else{
      FetchSessions();
    }
  }, []);

  const FetchMessages = async (session_id) => {
    setChosenSession(session_id);
    try {
      setLoadingMessages(true)
      const getToken = window.localStorage.getItem("token");
      const res = await fetch(BASE_URL + `list_messages/${session_id}/`, {
        headers: {
          Authorization: `Token ${getToken}`,
        },
      });
      const fetched_messages = await res.json();
      setLoadingMessages(false)
      setMessages(fetched_messages);
      // console.log( `fetched messages: ${JSON.stringify(fetched_messages)}`)
    } catch (error) {
      setErrorOccured(true);
    }
  };

  const NewSession = async () => {
    try {
      const getToken = window.localStorage.getItem("token");
      var current_timestamp = new Date();
      current_timestamp = current_timestamp.toISOString();
      const res = await fetch(BASE_URL + "new_session/", {
        method: "POST",
        headers: {
          Authorization: `Token ${getToken}`,
          "Content-type": "application/json",
        },
        body: JSON.stringify({ datetime_created: current_timestamp }),
      });
      const created_session = await res.json();
      setSessions([created_session, ...sessions]);
      // console.log( `created_session: ${JSON.stringify(created_session)}`)
    } catch (error) {
      setErrorOccured(true);
    }
  };

  const SendMessage = async () => {
    try {
      const user_message_obj = {'role': 'user', 'content': userMessage}
      setIsTyping(true)
      setMessages([...messages, user_message_obj]);
      setUserMessage("");
      const getToken = window.localStorage.getItem("token");
      var current_timestamp = new Date();
      current_timestamp = current_timestamp.toISOString();
      const res = await fetch(BASE_URL + `${chosenSession}/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${getToken}`,
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          datetime_sent: current_timestamp,
          content: userMessage,
        }),
      });
      const user_and_assistant_responses = await res.json();
      
      setMessages([...messages, ...user_and_assistant_responses]);
      setIsTyping(false)

      
      // console.log( `created_session: ${JSON.stringify(user_and_assistant_responses)}`)
    } catch (error) {
      setErrorOccured(true);
    }
  };

  return (
    <>
      <ModalErrorOccured
        errorOccured={errorOccured}
        setErrorOccured={setErrorOccured}
      />
      <Container fluid className="h-100 FontVazir PageBg" dir="ltr">
        <Row style={{ boxSizing: "border-box" }}>
          <NavBar />
        </Row>
        <Row
          id="content"
          className=" h-100"
          style={{ boxSizing: "border-box", paddingTop: "74px" }}
        >
          {showSidebar ? (
            <Col
              xs={4}
              md={3}
              lg={2}
              id="sidebar-wrapper"
              className="px-0 overflow-auto h-100 border-end border-2"
              style={{ backgroundColor: "#003582" }}
            >
              <Sidebar
                sessions={sessions}
                FetchMessages={FetchMessages}
                setShowSidebar={setShowSidebar}
                NewSession={NewSession}
                loadingSessions={loadingSessions}
              />
            </Col>
          ) : (
            <Col
              xs={2}
              sm={1}
              id="sidebar-wrapper-collapsed"
              className="px-0 h-100"
            >
              <Button
                className="mx-2 my-2"
                variant="outline-light"
                size="sm"
                onClick={() => setShowSidebar(true)}
              >
                <PiSidebarSimpleFill size={25} />
              </Button>
            </Col>
          )}
          <Col
            className=" text-light h-100 d-flex flex-column"
            id="page-content-wrapper"
          >
            <div className="h-100 d-flex flex-column col-sm-12 col-md-11 col-lg-8 mx-0 mx-sm-auto">
              <div className="d-flex flex-column overflow-auto mt-1 h-100">
                {loadingMessages ? 
                <>
                  <Row className="col-12 d-flex align-items-center h-50 text-center">
                    <Col className=" d-flex flex-column align-items-center">
                      <HashLoader className=" my-2" color="white" size={40} speedMultiplier={0.7}/>
                      <span className="my-2">Loading</span>
                    </Col>
                  </Row>
                </>
                
                
                
                : messages.map((message, index) => {
                  return (
                    <DialogueCard
                      key={index}
                      role={message.role}
                      content={message.content}
                      name={message.role}
                    />
                  );
                })}
                {isTyping && 
                <Row className=" mx-2 mt-2 mb-4 d-inline-flex flex-row justify-content-start align-items-center" > 
                  <span className=" col-3 col-sm-2  col-xl-1">Typing</span>
                  <PulseLoader className=" col-3 col-sm-2 col-xl-1" color="white" size={5} speedMultiplier={0.7}/>
                </Row>}
                <div ref={messagesRef}/>
              </div>

              <div className=" mt-auto">
                <InputBox
                  userMessage={userMessage}
                  setUserMessage={setUserMessage}
                  SendMessage={SendMessage}
                  isTyping={isTyping}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Chat;
