import React from "react";
import { Card } from "react-bootstrap";

const DialogueCard = ({ role, content, name }) => {
  
  if (role === "assistant") {
    const contents_list = content.split('\n');
    return (
      <Card
        className=" align-self-start text-light bgAssistant col-10 mb-2"
        dir="auto"
      >
        <Card.Header dir="auto">{name}</Card.Header>
        <Card.Body dir="auto">{contents_list.map((cont) => {
          return(
          <>
          {cont} <br />
          </>
          )
        })}</Card.Body>
      </Card>
    );
  }
  if (role === "user") {
    return (
      <Card
        className=" align-self-end text-light bgUser col-10 col-sm-8 mx-2 mb-2"
        dir="auto"
      >
        <Card.Header dir="auto">{name}</Card.Header>
        <Card.Body dir="auto">{content}</Card.Body>
      </Card>
    );
  }
};

export default DialogueCard;
