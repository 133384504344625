import React from "react";
import { Modal } from "react-bootstrap";
// import './styles/Home.scss'

const ModalErrorOccured = ({ errorOccured, setErrorOccured }) => {
  return (
    <>
      <Modal
        className="Vazirmatn"
        show={errorOccured}
        onHide={() => setErrorOccured(false)}
        dir="rtl"
      >
        <Modal.Header>
          <Modal.Title>خطایی رخ داده است</Modal.Title>
        </Modal.Header>
        <Modal.Body>لطفا پیج را ریفرش کرده و دوباره تلاش کنید.</Modal.Body>
      </Modal>
    </>
  );
};

export default ModalErrorOccured;
