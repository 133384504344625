import './App.css';
import Authentication from './pages/Authentication';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Chat from './pages/Chat'
import Home from './pages/Home';
import AuthContextProvider from './components/AuthContext';
import Login from './pages/Login';
import Signup from './pages/Signup';
function App() {
  const BASE_URL = 'https://zehnician-backend.onrender.com/'
  // const BASE_URL = 'http://localhost:8000/'
  return (
  <AuthContextProvider>
    <Router>
      <Routes>
        <Route path='/'>
          <Route path='' element={<Home BASE_URL={BASE_URL}/>}/>
          <Route path='auth'>
            <Route path='' element={<Authentication BASE_URL={BASE_URL}/>}/>
            <Route path='login' element={<Login BASE_URL={BASE_URL}/>}/>
            <Route path='signup' element={<Signup BASE_URL={BASE_URL}/>}/>
          </Route>
          <Route path='chat' element={<Chat BASE_URL={BASE_URL}/>} />
          <Route path={"*"} element={<h1 className='text-center text-light mt-4'>404: Not Found</h1>} />
        </Route>
        
       
        
        
        
      </Routes>
    </Router>
  </AuthContextProvider>
  );
}

export default App;
