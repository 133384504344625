import React from 'react'

const SectionTwoPicStruggle = () => {
  return (
    <>
      <svg width="100%" height="100%" viewBox="0 0 321 309" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M75.114 186.692C27.9655 212.895 27.972 255.376 75.114 281.573C122.256 307.77 198.693 307.776 245.835 281.573C292.977 255.37 292.977 212.895 245.835 186.692C198.693 160.488 122.282 160.495 75.114 186.692Z" fill="#F5F5F5"/>
      <path d="M89.2765 231.509C90.2585 232.852 91.4173 234.068 92.7241 235.124C97.2709 238.887 102.641 241.614 108.434 243.103C114.128 244.567 119.951 244.159 125.074 247.385C128.368 249.462 131.334 251.971 134.576 254.122C140.174 257.83 146.004 261.272 152.501 263.311C156.07 264.43 158.561 264.485 162.291 264.368C164.699 264.294 167.113 264.152 169.488 264.603C174.791 265.623 179.022 269.102 183.188 272.18C187.644 275.48 191.932 277.853 197.434 279.126C201.697 280.115 217.15 281.134 215.757 273.514C215.41 271.623 213.728 270.27 212.155 269.083C208.201 267.18 204.451 263.287 200.952 260.66C197.601 258.139 195.733 254.987 193.248 251.662C191.207 248.937 189.14 246.186 186.533 243.943C181.134 239.401 173.732 236.354 167.511 232.862C160.487 228.92 153.425 225.03 146.325 221.195C138.364 216.869 129.857 212.505 120.722 212.771C117.816 212.952 114.949 213.515 112.202 214.446C109.185 215.361 106.309 216.504 103.157 216.998C99.754 217.536 96.1652 217.511 93.0001 218.988C89.8351 220.465 87.4147 223.617 87.5752 226.985C87.7017 228.615 88.2902 230.179 89.2765 231.509Z" fill="#E0E0E0"/>
      <path d="M232.937 60.3356C233.186 59.9653 233.531 59.6645 233.94 59.4638C234.348 59.2631 234.803 59.1695 235.261 59.1923L248.371 61.1081C248.813 61.2146 249.219 61.4328 249.545 61.7402C249.871 62.0476 250.106 62.4331 250.226 62.8571L261.628 90.8896C262.028 92.0551 262.136 93.295 261.944 94.5085C261.752 95.722 261.266 96.8747 260.524 97.873L228.61 136.331C227.878 137.215 227.043 137.567 226.286 137.474L213.189 135.565C212.431 135.454 211.751 134.86 211.321 133.81L199.919 105.783C199.521 104.618 199.413 103.378 199.605 102.165C199.797 100.952 200.282 99.7989 201.023 98.8L232.937 60.3356Z" fill="#FAFAFA"/>
      <path d="M234.972 59.7916H235.139L236.423 59.977C239.485 60.4219 247.632 61.6085 248.255 61.7074C248.878 61.8063 249.289 62.2759 249.616 63.0732L261.018 91.0995C261.375 92.166 261.47 93.2978 261.295 94.4057C261.12 95.5135 260.68 96.5671 260.01 97.4834L228.109 135.96C227.935 136.215 227.705 136.43 227.436 136.59C227.166 136.75 226.863 136.852 226.549 136.887H226.382L215.07 135.218L213.266 134.953C212.707 134.872 212.226 134.384 211.898 133.587L200.497 105.561C200.143 104.495 200.05 103.364 200.225 102.258C200.4 101.152 200.838 100.1 201.504 99.1829L233.425 60.7186C233.598 60.4646 233.827 60.25 234.095 60.0899C234.364 59.9298 234.665 59.828 234.978 59.7916M234.978 59.1736C234.566 59.2064 234.167 59.3278 233.811 59.5289C233.454 59.73 233.149 60.0056 232.918 60.3354L201.004 98.7997C200.263 99.7986 199.777 100.951 199.586 102.165C199.394 103.378 199.501 104.617 199.899 105.783L211.301 133.809C211.732 134.86 212.412 135.453 213.17 135.565L226.266 137.474C226.354 137.48 226.442 137.48 226.53 137.474C226.942 137.444 227.342 137.323 227.699 137.122C228.056 136.921 228.361 136.644 228.59 136.312L260.504 97.8542C261.246 96.8559 261.733 95.7032 261.925 94.4897C262.117 93.2763 262.008 92.0364 261.609 90.8708L250.207 62.8383C250.087 62.4144 249.852 62.0289 249.525 61.7215C249.199 61.4141 248.794 61.1959 248.351 61.0894L235.242 59.1736C235.154 59.1672 235.066 59.1672 234.978 59.1736Z" fill="#F0F0F0"/>
      <path d="M247.433 95.9631L215.519 134.421C215.277 134.778 214.947 135.071 214.558 135.273C214.169 135.476 213.734 135.583 213.292 135.583C214.659 135.781 225.547 137.363 226.279 137.48C226.738 137.502 227.195 137.407 227.604 137.205C228.013 137.004 228.36 136.702 228.61 136.331L260.524 97.8728C261.267 96.9104 261.748 95.7845 261.923 94.5974C257.23 93.9114 252.723 93.2439 248.833 92.6877C248.657 93.8748 248.176 95.0008 247.433 95.9631Z" fill="#E0E0E0"/>
      <path d="M248.531 88.9923L237.129 60.9475C236.699 59.8969 236.018 59.3036 235.261 59.1923C234.803 59.1695 234.348 59.2631 233.94 59.4638C233.531 59.6645 233.186 59.9653 232.937 60.3356L201.023 98.8C200.282 99.7989 199.797 100.952 199.605 102.165C199.413 103.378 199.521 104.618 199.919 105.783L211.321 133.81C211.751 134.86 212.431 135.454 213.189 135.565C213.946 135.676 214.781 135.305 215.513 134.421L247.427 95.9572C248.165 94.9607 248.649 93.8112 248.841 92.6014C249.033 91.3916 248.927 90.1554 248.531 88.9923Z" fill="#F0F0F0"/>
      <path opacity="0.1" d="M225.092 122.883L225.502 115.331C229.701 107.495 225.682 99.2632 222.498 97.5143C231.756 88.0589 229.188 74.6483 226.504 68.0913L201.023 98.7997C200.282 99.7986 199.797 100.951 199.605 102.165C199.413 103.378 199.521 104.617 199.919 105.783L211.321 133.809C211.751 134.86 212.431 135.453 213.189 135.565L226.286 137.474C227.043 137.585 227.878 137.215 228.61 136.331L237.887 125.151L225.092 122.883Z" fill="black"/>
      <path d="M68.9251 94.9495C67.7053 94.5046 66.6845 94.6034 66.0233 95.1226C65.362 95.6417 55.212 103.595 54.57 104.127C53.928 104.658 53.6134 105.585 53.8189 106.821L59.3978 139.983C59.7009 141.321 60.3275 142.571 61.2265 143.632C62.1256 144.693 63.2719 145.535 64.5724 146.089L117.916 165.624C119.136 166.069 120.157 165.97 120.818 165.451L132.252 156.434C132.894 155.909 133.221 154.97 133.01 153.728L127.431 120.566C127.127 119.228 126.501 117.978 125.602 116.917C124.703 115.856 123.556 115.014 122.256 114.46L68.9251 94.9495Z" fill="#FAFAFA"/>
      <path d="M67.4742 95.2832C67.8946 95.2922 68.31 95.3739 68.7004 95.5243L122.057 115.035C123.249 115.556 124.299 116.336 125.126 117.312C125.954 118.289 126.536 119.436 126.827 120.664L132.406 153.826C132.567 154.803 132.406 155.557 131.873 155.952L120.414 164.95C120.114 165.17 119.743 165.282 119.367 165.266C118.941 165.254 118.521 165.166 118.128 165.006L64.7714 145.496C63.579 144.975 62.528 144.196 61.7005 143.219C60.873 142.243 60.2914 141.095 60.0013 139.866L54.4416 106.722C54.2747 105.752 54.4416 104.998 54.968 104.603C55.4945 104.207 62.6271 98.5834 65.3042 96.4822L66.4277 95.5984C66.7278 95.3793 67.0979 95.2678 67.4742 95.2832ZM67.4742 94.6652C66.95 94.6505 66.4369 94.8122 66.0233 95.1226C65.3813 95.6417 55.2184 103.595 54.5571 104.127C53.8959 104.658 53.6006 105.585 53.806 106.821L59.385 139.983C59.6881 141.321 60.3146 142.571 61.2137 143.632C62.1128 144.693 63.2591 145.535 64.5595 146.089L117.916 165.624C118.378 165.802 118.87 165.898 119.367 165.908C119.891 165.924 120.405 165.762 120.818 165.451L132.252 156.434C132.894 155.909 133.221 154.97 133.01 153.727L127.431 120.566C127.127 119.228 126.501 117.978 125.602 116.917C124.703 115.856 123.556 115.014 122.256 114.46L68.8994 94.9495C68.4378 94.7706 67.9461 94.6742 67.4485 94.6652H67.4742Z" fill="#F0F0F0"/>
      <path d="M76.0192 137.097L129.363 156.607C130.531 157.034 131.514 156.972 132.188 156.502C130.994 157.448 121.466 164.919 120.824 165.432C120.182 165.945 119.136 166.05 117.91 165.599L64.5659 146.089C63.2835 145.571 62.1558 144.753 61.2853 143.71C65.3941 140.484 69.3295 137.381 72.745 134.718C73.6099 135.765 74.7364 136.583 76.0192 137.097Z" fill="#E0E0E0"/>
      <path d="M70.8447 131.016L65.2657 97.8541C65.0538 96.6181 65.362 95.6726 66.0232 95.1534C66.6845 94.6343 67.7053 94.5354 68.9251 94.9804L122.282 114.491C123.582 115.045 124.728 115.887 125.628 116.948C126.527 118.009 127.153 119.259 127.456 120.596L133.035 153.758C133.247 154.994 132.939 155.94 132.278 156.465C131.616 156.991 130.596 157.083 129.376 156.638L76.0192 137.128C74.7186 136.572 73.5723 135.729 72.6733 134.667C71.7743 133.605 71.1478 132.354 70.8447 131.016Z" fill="#F0F0F0"/>
      <path opacity="0.1" d="M127.456 120.566C127.153 119.228 126.527 117.978 125.628 116.917C124.728 115.856 123.582 115.014 122.282 114.46L88.3328 102.044L88.9299 113.588L91.7097 115.133C88.6695 118.586 86.4557 122.64 85.2253 127.008C83.9949 131.376 83.778 135.952 84.5899 140.41L73.5219 149.364L117.916 165.624C119.136 166.069 120.157 165.97 120.818 165.451L132.252 156.434C132.894 155.909 133.221 154.97 133.01 153.728L127.456 120.566Z" fill="black"/>
      <path d="M170.637 135.169C161.252 135.169 152.078 132.49 144.275 127.471C136.472 122.452 130.39 115.318 126.799 106.972C123.208 98.6259 122.268 89.4419 124.099 80.5815C125.93 71.7212 130.449 63.5824 137.085 57.1945C143.721 50.8065 152.176 46.4563 161.38 44.6938C170.585 42.9314 180.125 43.836 188.796 47.2931C197.466 50.7502 204.877 56.6047 210.091 64.1161C215.305 71.6276 218.087 80.4586 218.087 89.4926C218.072 101.602 213.068 113.212 204.173 121.774C195.277 130.337 183.217 135.154 170.637 135.169ZM170.637 44.5269C161.399 44.5281 152.368 47.1663 144.687 52.108C137.006 57.0496 131.02 64.0727 127.486 72.2892C123.951 80.5057 123.027 89.5466 124.83 98.2687C126.633 106.991 131.083 115.002 137.616 121.29C144.149 127.578 152.472 131.86 161.533 133.595C170.594 135.329 179.986 134.438 188.521 131.035C197.056 127.631 204.351 121.868 209.484 114.474C214.616 107.079 217.356 98.3857 217.356 89.4926C217.342 77.5699 212.415 66.1393 203.657 57.7093C194.898 49.2793 183.023 44.5383 170.637 44.5269Z" fill="#7BA1B4"/>
      <path d="M152.719 138.03C143.288 138.03 134.07 135.338 126.229 130.295C118.387 125.252 112.276 118.083 108.667 109.696C105.058 101.309 104.114 92.0805 105.954 83.177C107.794 74.2735 112.335 66.0951 119.003 59.676C125.672 53.257 134.168 48.8855 143.417 47.1145C152.666 45.3435 162.253 46.2524 170.966 49.7264C179.678 53.2004 187.125 59.0834 192.365 66.6314C197.604 74.1794 200.4 83.0535 200.4 92.1314C200.387 104.301 195.359 115.967 186.42 124.572C177.481 133.177 165.361 138.017 152.719 138.03ZM152.719 46.9433C143.435 46.9433 134.359 49.5935 126.639 54.5588C118.919 59.5242 112.902 66.5816 109.349 74.8387C105.796 83.0957 104.867 92.1816 106.678 100.947C108.489 109.713 112.96 117.765 119.525 124.084C126.09 130.404 134.455 134.708 143.561 136.451C152.667 138.195 162.106 137.3 170.683 133.88C179.261 130.46 186.593 124.668 191.751 117.237C196.909 109.805 199.662 101.069 199.662 92.1314C199.643 80.1546 194.691 68.6738 185.891 60.2072C177.09 51.7406 165.161 46.9794 152.719 46.968V46.9433Z" fill="#7BA1B4"/>
      <path d="M132.483 139.161C123.053 139.161 113.834 136.469 105.993 131.426C98.1516 126.382 92.0402 119.214 88.4313 110.827C84.8224 102.44 83.8782 93.2115 85.718 84.308C87.5578 75.4045 92.099 67.2261 98.7673 60.807C105.436 54.3879 113.932 50.0165 123.181 48.2455C132.43 46.4745 142.017 47.3834 150.73 50.8574C159.443 54.3314 166.889 60.2143 172.129 67.7624C177.368 75.3104 180.164 84.1845 180.164 93.2624C180.151 105.432 175.123 117.098 166.184 125.703C157.245 134.308 145.125 139.148 132.483 139.161ZM132.483 48.0742C123.199 48.0742 114.123 50.7245 106.403 55.6898C98.6832 60.6552 92.6664 67.7126 89.1134 75.9696C85.5604 84.2267 84.6308 93.3125 86.4421 102.078C88.2534 110.844 92.7243 118.896 99.2894 125.215C105.854 131.535 114.219 135.839 123.325 137.582C132.431 139.326 141.87 138.431 150.447 135.011C159.025 131.591 166.357 125.799 171.515 118.368C176.673 110.936 179.426 102.2 179.426 93.2624C179.413 81.2818 174.462 69.7956 165.662 61.324C156.861 52.8524 144.929 48.0873 132.483 48.0742Z" fill="#7BA1B4"/>
      <path d="M166.618 120.819C157.188 120.819 147.969 118.127 140.128 113.084C132.287 108.04 126.175 100.872 122.566 92.4849C118.958 84.0979 118.013 74.8692 119.853 65.9657C121.693 57.0622 126.234 48.8838 132.902 42.4647C139.571 36.0457 148.067 31.6742 157.316 29.9032C166.565 28.1322 176.152 29.0411 184.865 32.5151C193.578 35.9891 201.025 41.8721 206.264 49.4201C211.503 56.9681 214.3 65.8422 214.3 74.9201C214.288 87.0897 209.26 98.7576 200.321 107.363C191.381 115.968 179.26 120.808 166.618 120.819ZM166.618 29.7381C157.334 29.7381 148.257 32.3885 140.537 37.3541C132.818 42.3198 126.801 49.3776 123.248 57.635C119.695 65.8924 118.766 74.9785 120.578 83.7443C122.39 92.5102 126.861 100.562 133.427 106.881C139.993 113.201 148.358 117.504 157.464 119.247C166.571 120.99 176.009 120.094 184.587 116.673C193.165 113.252 200.496 107.459 205.653 100.027C210.81 92.595 213.563 83.8578 213.561 74.9201C213.546 62.9406 208.595 51.456 199.795 42.9857C190.994 34.5155 179.063 29.7512 166.618 29.7381Z" fill="#7BA1B4"/>
      <path d="M159.004 123.761C149.573 123.761 140.354 121.069 132.513 116.025C124.672 110.981 118.56 103.812 114.952 95.4252C111.343 87.0379 110.399 77.8089 112.239 68.9052C114.08 60.0015 118.622 51.8231 125.291 45.4043C131.96 38.9855 140.456 34.6145 149.706 32.8442C158.956 31.0739 168.543 31.9837 177.255 35.4587C185.968 38.9336 193.414 44.8175 198.653 52.3664C203.891 59.9152 206.687 68.7899 206.685 77.8681C206.67 90.0361 201.642 101.701 192.703 110.305C183.764 118.909 171.645 123.748 159.004 123.761ZM159.004 32.6799C149.72 32.6799 140.644 35.3302 132.924 40.2955C125.204 45.2608 119.187 52.3183 115.634 60.5753C112.081 68.8324 111.152 77.9182 112.963 86.6838C114.774 95.4495 119.245 103.501 125.81 109.821C132.375 116.141 140.74 120.444 149.846 122.188C158.952 123.932 168.391 123.037 176.968 119.616C185.546 116.196 192.878 110.404 198.036 102.973C203.194 95.5421 205.947 86.8054 205.947 77.8681C205.934 65.8874 200.983 54.4013 192.183 45.9297C183.382 37.4581 171.45 32.693 159.004 32.6799Z" fill="#7BA1B4"/>
      <path d="M159.004 120.244C153.46 120.244 148.041 118.662 143.432 115.697C138.822 112.732 135.23 108.518 133.108 103.588C130.987 98.6576 130.431 93.2325 131.513 87.9985C132.595 82.7645 135.264 77.9568 139.184 74.1833C143.104 70.4099 148.099 67.8401 153.536 66.799C158.973 65.7579 164.609 66.2922 169.731 68.3344C174.852 70.3766 179.23 73.8349 182.31 78.2721C185.39 82.7092 187.034 87.9259 187.034 93.2624C187.025 100.416 184.07 107.274 178.815 112.332C173.56 117.391 166.435 120.236 159.004 120.244ZM159.004 66.9912C153.606 66.9912 148.33 68.532 143.842 71.4187C139.354 74.3054 135.856 78.4084 133.79 83.2089C131.725 88.0093 131.184 93.2916 132.237 98.3877C133.29 103.484 135.889 108.165 139.706 111.839C143.523 115.513 148.386 118.015 153.68 119.029C158.974 120.042 164.461 119.522 169.448 117.534C174.435 115.545 178.697 112.178 181.696 107.858C184.695 103.538 186.296 98.4584 186.296 93.2624C186.287 86.2974 183.409 79.6199 178.293 74.6949C173.176 69.7699 166.24 66.9994 159.004 66.9912Z" fill="#7BA1B4"/>
      <path d="M142.909 100.734C137.897 100.734 132.998 99.3034 128.831 96.6232C124.664 93.9429 121.416 90.1333 119.498 85.6762C117.58 81.219 117.079 76.3145 118.056 71.5828C119.034 66.8512 121.447 62.5048 124.991 59.0935C128.535 55.6821 133.05 53.359 137.966 52.4178C142.881 51.4766 147.976 51.9597 152.606 53.8059C157.237 55.6521 161.194 58.7785 163.978 62.7898C166.763 66.8012 168.249 71.5172 168.249 76.3416C168.242 82.8088 165.57 89.0094 160.82 93.5824C156.069 98.1555 149.628 100.727 142.909 100.734ZM142.909 52.6598C138.044 52.661 133.288 54.051 129.243 56.6539C125.198 59.2568 122.046 62.9558 120.185 67.2832C118.324 71.6106 117.838 76.372 118.788 80.9654C119.738 85.5588 122.081 89.778 125.522 93.0893C128.963 96.4006 133.346 98.6555 138.118 99.5687C142.891 100.482 147.837 100.013 152.332 98.2201C156.827 96.4275 160.668 93.3921 163.371 89.4978C166.074 85.6035 167.517 81.0251 167.517 76.3416C167.512 70.0606 164.918 64.0384 160.304 59.5971C155.69 55.1558 149.434 52.6585 142.909 52.6536V52.6598Z" fill="#7BA1B4"/>
      <path d="M149.631 104.195C144.618 104.195 139.718 102.764 135.549 100.083C131.381 97.402 128.133 93.5915 126.214 89.1332C124.296 84.6749 123.794 79.7692 124.772 75.0363C125.75 70.3034 128.164 65.956 131.709 62.5438C135.253 59.1316 139.769 56.8078 144.686 55.8664C149.603 54.925 154.699 55.4082 159.331 57.2548C163.962 59.1015 167.92 62.2287 170.706 66.2411C173.491 70.2534 174.977 74.9706 174.977 79.7962C174.97 86.2651 172.298 92.4672 167.546 97.0415C162.794 101.616 156.351 104.188 149.631 104.195ZM149.631 56.1145C144.764 56.1145 140.006 57.5039 135.959 60.107C131.912 62.7102 128.758 66.4101 126.896 70.7389C125.033 75.0676 124.546 79.8308 125.496 84.426C126.446 89.0212 128.791 93.2419 132.233 96.5545C135.675 99.8671 140.06 102.123 144.834 103.036C149.608 103.949 154.556 103.479 159.052 101.685C163.549 99.8916 167.392 96.8545 170.095 92.9582C172.798 89.062 174.24 84.4815 174.239 79.7962C174.232 73.5164 171.637 67.4957 167.023 63.0557C162.41 58.6158 156.155 56.1194 149.631 56.1145Z" fill="#7BA1B4"/>
      <path d="M134.236 91.1922C129.224 91.1922 124.325 89.7616 120.158 87.0813C115.991 84.401 112.743 80.5914 110.825 76.1343C108.907 71.6771 108.405 66.7726 109.383 62.041C110.361 57.3093 112.774 52.963 116.318 49.5516C119.862 46.1403 124.377 43.8171 129.292 42.8759C134.208 41.9347 139.303 42.4178 143.933 44.264C148.563 46.1102 152.521 49.2367 155.305 53.248C158.089 57.2593 159.575 61.9753 159.575 66.7997C159.567 73.2665 156.895 79.466 152.144 84.0387C147.394 88.6114 140.954 91.184 134.236 91.1922ZM134.236 43.1118C129.369 43.1118 124.611 44.501 120.564 47.1039C116.518 49.7068 113.364 53.4063 111.501 57.7347C109.639 62.0631 109.151 66.826 110.101 71.421C111.05 76.016 113.394 80.2368 116.835 83.5496C120.277 86.8624 124.662 89.1185 129.435 90.0325C134.208 90.9465 139.156 90.4774 143.653 88.6845C148.149 86.8916 151.992 83.8555 154.696 79.96C157.4 76.0646 158.844 71.4847 158.844 66.7997C158.837 60.5193 156.242 54.498 151.629 50.057C147.015 45.6161 140.76 43.1183 134.236 43.1118Z" fill="#7BA1B4"/>
      <path d="M144.322 91.6247C139.573 91.6259 134.932 90.2716 130.983 87.733C127.035 85.1945 123.957 81.5857 122.139 77.3632C120.322 73.1407 119.846 68.4941 120.772 64.0112C121.698 59.5283 123.984 55.4105 127.342 52.1785C130.699 48.9466 134.977 46.7457 139.634 45.8543C144.291 44.963 149.118 45.4211 153.504 47.1708C157.891 48.9204 161.64 51.8831 164.277 55.684C166.914 59.4848 168.321 63.9532 168.32 68.5239C168.311 74.6481 165.78 80.5191 161.281 84.8496C156.783 89.1801 150.684 91.6165 144.322 91.6247ZM144.322 46.1275C139.719 46.1226 135.218 47.432 131.389 49.89C127.559 52.3479 124.573 55.8441 122.808 59.936C121.043 64.028 120.579 68.5319 121.474 72.878C122.369 77.2241 124.582 81.217 127.835 84.3517C131.088 87.4863 135.234 89.6218 139.747 90.488C144.261 91.3542 148.941 90.9121 153.194 89.2177C157.446 87.5233 161.082 84.6527 163.639 80.9692C166.197 77.2856 167.562 72.9545 167.562 68.5239C167.555 62.5903 165.105 56.9012 160.749 52.7032C156.393 48.5052 150.486 46.1406 144.322 46.1275Z" fill="#7BA1B4"/>
      <path d="M169.225 94.9125C163.681 94.9113 158.263 93.3278 153.654 90.3622C149.045 87.3967 145.454 83.1823 143.333 78.2519C141.213 73.3215 140.659 67.8965 141.741 62.6629C142.823 57.4294 145.493 52.6222 149.413 48.8494C153.334 45.0765 158.328 42.5073 163.765 41.4667C169.202 40.4261 174.838 40.9608 179.959 43.0032C185.081 45.0456 189.458 48.5039 192.538 52.9409C195.617 57.3779 197.261 62.5944 197.261 67.9306C197.252 75.0852 194.296 81.9443 189.04 87.0028C183.783 92.0613 176.657 94.906 169.225 94.9125ZM169.225 41.6594C163.827 41.6607 158.551 43.2025 154.064 46.0899C149.577 48.9774 146.08 53.0808 144.015 57.8814C141.951 62.6819 141.411 67.964 142.465 73.0597C143.519 78.1554 146.119 82.8359 149.936 86.5093C153.753 90.1828 158.615 92.6843 163.909 93.6975C169.203 94.7106 174.69 94.19 179.677 92.2014C184.663 90.2128 188.925 86.8456 191.924 82.5255C194.922 78.2053 196.523 73.1263 196.523 67.9306C196.514 60.9645 193.635 54.2862 188.518 49.3609C183.4 44.4357 176.461 41.666 169.225 41.6594Z" fill="#7BA1B4"/>
      <path d="M149.631 114.423C140.2 114.423 130.982 111.731 123.141 106.687C115.299 101.644 109.188 94.4755 105.579 86.0886C101.97 77.7017 101.026 68.473 102.866 59.5695C104.706 50.666 109.247 42.4876 115.915 36.0685C122.583 29.6494 131.08 25.278 140.329 23.507C149.578 21.7359 159.165 22.6449 167.878 26.1189C176.59 29.5928 184.037 35.4758 189.277 43.0238C194.516 50.5719 197.312 59.4459 197.312 68.5239C197.299 80.693 192.271 92.3599 183.332 100.965C174.393 109.57 162.273 114.41 149.631 114.423ZM149.631 23.3357C140.347 23.3369 131.271 25.9882 123.553 30.9543C115.834 35.9204 109.818 42.9782 106.266 51.2354C102.714 59.4926 101.785 68.5782 103.597 77.3434C105.409 86.1087 109.881 94.1599 116.446 100.479C123.011 106.798 131.376 111.101 140.482 112.844C149.587 114.587 159.026 113.692 167.603 110.272C176.181 106.851 183.512 101.059 188.67 93.6284C193.827 86.1974 196.58 77.461 196.58 68.5239C196.567 56.5422 191.616 45.0551 182.814 36.5833C174.012 28.1115 162.078 23.3472 149.631 23.3357Z" fill="#7BA1B4"/>
      <path d="M132.483 120.819C123.053 120.819 113.834 118.127 105.993 113.084C98.1516 108.04 92.0402 100.872 88.4313 92.4849C84.8224 84.0979 83.8782 74.8692 85.718 65.9657C87.5578 57.0622 92.099 48.8838 98.7673 42.4647C105.436 36.0457 113.932 31.6742 123.181 29.9032C132.43 28.1322 142.017 29.0411 150.73 32.5151C159.443 35.9891 166.889 41.8721 172.129 49.4201C177.368 56.9681 180.164 65.8422 180.164 74.9201C180.211 80.9603 179.01 86.9491 176.631 92.5382C174.251 98.1274 170.741 103.205 166.304 107.476C161.867 111.747 156.592 115.127 150.785 117.417C144.979 119.708 138.758 120.864 132.483 120.819ZM132.483 29.7381C120.129 29.8772 108.33 34.699 99.6448 43.1577C90.9598 51.6165 86.089 63.0301 86.089 74.9232C86.089 86.8163 90.9598 98.2299 99.6448 106.689C108.33 115.147 120.129 119.969 132.483 120.108C138.694 120.178 144.857 119.061 150.615 116.822C156.374 114.582 161.614 111.265 166.031 107.062C170.449 102.86 173.956 97.8548 176.349 92.338C178.743 86.8211 179.975 80.9019 179.975 74.9232C179.975 68.9445 178.743 63.0253 176.349 57.5085C173.956 51.9916 170.449 46.9868 166.031 42.7841C161.614 38.5813 156.374 35.2641 150.615 33.0247C144.857 30.7853 138.694 29.6682 132.483 29.7381Z" fill="#7BA1B4"/>
      <path d="M216.758 95.2586C219.59 94.8816 222.171 92.7062 223.82 88.819C227.114 81.0384 225.445 69.3582 220.09 62.7209C217.42 59.4022 214.312 57.9808 211.494 58.3516L150.414 66.4351C147.583 66.812 145.009 68.9874 143.352 72.8746C140.059 80.6552 141.728 92.3354 147.082 98.9727C149.759 102.291 152.86 103.713 155.679 103.342L216.758 95.2586Z" fill="url(#paint0_linear_56_12)"/>
      <path opacity="0.1" d="M186.212 80.0991L157.849 95.3142L157.207 74.0859L146.293 68.5239C145.024 69.7741 144.034 71.261 143.384 72.8932C140.091 80.6738 141.76 92.354 147.114 98.9913C149.791 102.31 152.892 103.731 155.711 103.361L213.272 95.7406L186.212 80.0991Z" fill="black"/>
      <path opacity="0.2" d="M150.292 68.0295C154.985 66.8615 183.381 62.072 200.079 59.8657L150.414 66.4351C147.583 66.812 145.009 68.9874 143.352 72.8746C141.227 77.9051 141.182 84.561 142.851 90.432C141.086 80.2288 145.612 69.2161 150.292 68.0295Z" fill="white"/>
      <path d="M220.084 62.7209C217.413 59.4022 214.306 57.9808 211.488 58.3516C208.669 58.7224 206.082 60.904 204.426 64.7912C201.132 72.5718 202.801 84.2582 208.149 90.8893C210.826 94.208 213.927 95.6356 216.746 95.2586C219.564 94.8816 222.158 92.7062 223.808 88.819C227.107 81.0384 225.438 69.3582 220.084 62.7209Z" fill="url(#paint1_linear_56_12)"/>
      <path opacity="0.2" d="M220.084 62.7209C217.413 59.4022 214.306 57.9808 211.488 58.3516C208.669 58.7224 206.082 60.904 204.426 64.7912C201.132 72.5718 202.801 84.2582 208.149 90.8893C210.826 94.208 213.927 95.6356 216.746 95.2586C219.564 94.8816 222.158 92.7062 223.808 88.819C227.107 81.0384 225.438 69.3582 220.084 62.7209Z" fill="black"/>
      <path d="M91.7097 76.8916L92.9359 111.463L123.425 93.1573L122.198 58.5864L91.7097 76.8916Z" fill="#455A64"/>
      <path opacity="0.05" d="M122.198 58.5864L121.145 92.2612L92.9359 111.463L123.424 93.1573L122.198 58.5864Z" fill="white"/>
      <path d="M122.198 58.5864L153.913 74.8522L155.139 109.423L123.425 93.1573L122.198 58.5864Z" fill="#37474F"/>
      <path d="M92.9359 111.463L124.651 127.728L155.139 109.423L123.424 93.1573L92.9359 111.463Z" fill="#263238"/>
      <path d="M116.683 123.248L151.448 142.566L220.976 103.929L186.212 84.6042L116.683 123.248Z" fill="url(#paint2_linear_56_12)"/>
      <path opacity="0.5" d="M116.683 123.248L151.448 142.566L220.976 103.929L186.212 84.6042L116.683 123.248Z" fill="white"/>
      <g opacity="0.1">
      <path d="M150.485 104.461L213.176 108.267L220.976 103.923L215.949 101.13L162.33 97.8789L150.485 104.461Z" fill="white"/>
      <path d="M125.35 118.427L118.808 122.067L181.5 125.868L188.042 122.228L125.35 118.427Z" fill="white"/>
      <path d="M171.176 92.9658L167.556 94.9743L209.44 97.5143L204.933 95.0114L171.176 92.9658Z" fill="white"/>
      <path d="M166.265 134.335L178.116 127.747L118.25 124.119L133.003 132.314L166.265 134.335Z" fill="white"/>
      <path d="M142.126 109.108L138.505 111.117L201.196 114.917L204.817 112.909L142.126 109.108Z" fill="white"/>
      </g>
      <path d="M116.683 123.248L151.448 142.566V165.42L116.683 146.101V123.248Z" fill="url(#paint3_linear_56_12)"/>
      <path opacity="0.3" d="M116.683 123.248L151.448 142.566V165.42L116.683 146.101V123.248Z" fill="white"/>
      <path d="M220.976 103.929V126.783L151.448 165.42V142.566L220.976 103.929Z" fill="url(#paint4_linear_56_12)"/>
      <g opacity="0.15">
      <path d="M220.976 103.929V126.783L151.448 165.42V142.566L220.976 103.929Z" fill="white"/>
      </g>
      <path d="M119.874 106.543C116.805 106.247 113.707 106.405 110.687 107.013C108.268 107.585 105.905 108.358 103.625 109.324C96.9657 112.414 91.6936 117.721 88.7834 124.262C85.8732 130.804 85.5219 138.139 87.7944 144.911C90.0668 151.682 94.8094 157.433 101.145 161.1C107.481 164.767 114.982 166.101 122.261 164.857C129.539 163.613 136.104 159.874 140.741 154.331C145.378 148.789 147.774 141.818 147.485 134.707C147.196 127.596 144.243 120.826 139.171 115.649C134.099 110.471 127.251 107.237 119.894 106.543H119.874Z" fill="url(#paint5_linear_56_12)"/>
      <path opacity="0.15" d="M127.559 155.619C133.446 152.461 130.801 145.891 121.287 143.401C111.772 140.91 101.5 143.141 104.595 151.806C91.6904 145.242 88.352 121.882 100.39 111.098C96.1645 113.707 92.6722 117.278 90.221 121.495C87.7699 125.712 86.4354 130.445 86.3354 135.276C86.2354 140.107 87.3731 144.888 89.6478 149.195C91.9226 153.503 95.2645 157.205 99.3781 159.974C103.492 162.743 108.251 164.495 113.234 165.073C118.218 165.652 123.273 165.04 127.953 163.292C132.633 161.544 136.793 158.713 140.067 155.05C143.34 151.387 145.626 147.004 146.723 142.288C145.137 146.985 138.768 155.198 127.559 155.619Z" fill="black"/>
      <path opacity="0.2" d="M142.569 135.231C141.721 137.307 139.629 139.025 137.33 138.939C133.851 138.852 131.815 135.33 129.902 132.536C124.972 125.374 116.703 120.85 108.126 118.434C107.04 118.191 106.004 117.773 105.063 117.198C104.602 116.901 104.237 116.485 104.01 115.997C103.782 115.51 103.703 114.97 103.779 114.441C104.1 113.374 104.797 112.447 105.75 111.821C107.971 110.645 110.422 109.928 112.947 109.714C119.367 109.164 126.089 111.024 131.52 114.293C136.951 117.562 141.15 122.562 142.691 128.606C143.217 130.806 143.41 133.154 142.569 135.231Z" fill="white"/>
      <path opacity="0.2" d="M132.849 131.523C133.231 132.722 133.919 133.811 134.852 134.687C135.406 135.237 136.102 135.636 136.868 135.843C144.797 137.697 140.47 119.515 134.505 123.847C133.533 124.639 132.877 125.731 132.65 126.937C132.294 128.456 132.363 130.038 132.849 131.523Z" fill="white"/>
      <path d="M260.273 102.193C230.099 153.097 167.08 178.658 107.907 166.465C88.5767 162.46 70.1642 154.568 54.6213 143.203C54.2323 142.957 53.9606 142.573 53.8661 142.134C53.7716 141.695 53.862 141.238 54.1174 140.864C54.3728 140.489 54.7723 140.228 55.228 140.137C55.6837 140.046 56.1583 140.133 56.5473 140.379C73.049 149.106 90.9724 155.068 109.551 158.01C113.936 158.628 118.969 159.246 123.405 159.506C130.352 159.951 137.322 159.963 144.27 159.543C147.602 159.364 151.332 158.882 154.645 158.585C156.359 158.313 159.781 157.788 161.508 157.522C163.755 157.225 169.392 155.915 171.696 155.458C173.937 154.877 176.152 154.222 178.386 153.635C181.628 152.819 185.076 151.478 188.273 150.458C191.374 149.253 194.879 147.949 197.903 146.596L204.188 143.691C208.284 141.571 212.393 139.452 216.29 136.998C217.953 136.022 220.572 134.421 222.132 133.29C223.692 132.159 226.35 130.509 227.827 129.347L233.348 125.157C235.126 123.693 236.853 122.16 238.631 120.677C245.614 114.51 252.102 107.843 258.039 100.734C258.249 100.477 258.552 100.305 258.888 100.254C259.224 100.202 259.567 100.275 259.85 100.457C260.132 100.639 260.333 100.917 260.411 101.236C260.49 101.554 260.44 101.889 260.273 102.174V102.193Z" fill="#37474F"/>
      <path d="M200.875 256.099C200.875 256.099 194.616 252.02 199.38 246.032C200.054 245.185 216.38 229 216.38 229L198.847 219.254L184.967 241.638L184.106 243.016C184.106 243.016 178.88 254.344 186.713 255.858C190.725 256.624 200.875 256.099 200.875 256.099Z" fill="#FFA8A7"/>
      <path d="M178.226 253.275C178.065 253.957 178.123 254.671 178.393 255.32C178.714 256.198 183.15 259.548 184.292 260.975C185.435 262.403 185.576 262.984 186.86 264.943C188.299 267.112 189.082 269.788 195.431 271.926C201.781 274.064 206.14 273.354 209.003 272.173C210.017 271.694 210.897 270.988 211.571 270.115C211.571 270.115 212.605 266.624 210.602 265.845L178.226 253.275Z" fill="#263238"/>
      <path d="M195.682 247.571C196.513 247.655 197.325 247.864 198.089 248.189C198.311 248.25 198.51 248.371 198.662 248.537C198.814 248.704 198.914 248.909 198.949 249.128C198.982 249.629 198.789 250.117 198.866 250.618C198.97 251.148 199.189 251.651 199.508 252.095C201.785 255.438 204.557 258.442 207.738 261.012C209.812 262.774 211.866 264.72 211.988 267.545C212.065 269.343 209.671 271.728 201.543 271.92C197.396 272.019 191.117 269.287 189.262 266.772C187.406 264.257 186.623 262.063 185.121 260.221C183.618 258.38 179.047 255.426 178.2 253.275C178.164 251.617 178.499 249.971 179.182 248.448C179.754 246.848 181.911 244.462 182.334 243.443C182.545 242.933 182.901 242.491 183.363 242.169C183.825 241.847 184.372 241.659 184.941 241.626L184.094 243.066C181.975 247.243 186.424 252.076 186.745 251.965C187.182 251.823 187.175 250.37 187.284 250C187.612 248.924 188.299 248.498 189.345 248.108C190.942 247.625 192.616 247.415 194.288 247.49C194.754 247.488 195.22 247.515 195.682 247.571Z" fill="#455A64"/>
      <path d="M201.627 271.92C197.479 272.019 191.2 269.287 189.345 266.772C188.734 265.937 188.174 265.069 187.669 264.17C187.811 264.325 187.945 264.492 188.067 264.615C188.35 264.887 188.632 265.153 188.928 265.412C190.043 266.482 191.425 267.258 192.94 267.668C194.301 267.946 197.646 268.743 198.718 267.458C199.36 266.729 199.052 265.555 198.404 264.646C198.134 264.263 197.293 263.354 197.293 263.354C198.522 262.351 199.909 261.541 201.402 260.957C204.195 259.98 204.432 260.258 204.432 260.258C204.921 260.781 205.496 261.224 206.133 261.568C206.759 261.897 207.451 262.093 208.162 262.143C208.418 262.139 208.673 262.104 208.92 262.038C210.743 263.46 212.13 264.85 212.072 267.545C211.931 269.337 209.748 271.722 201.627 271.92Z" fill="#37474F"/>
      <path d="M202.557 257.551C203.148 258.159 203.571 258.898 203.79 259.702C203.797 260.037 203.84 260.371 203.918 260.697C204.349 261.68 205.658 261.062 205.896 260.258C205.974 259.987 205.998 259.704 205.966 259.424C203.506 257.258 201.348 254.795 199.546 252.095C199.353 251.806 199.186 251.502 199.046 251.186V251.155C199.156 251.886 199.356 252.602 199.643 253.287C200.443 254.811 201.421 256.241 202.557 257.551Z" fill="#37474F"/>
      <path d="M182.854 249.196C182.202 248.017 181.906 246.685 182.001 245.352C182.093 244.567 182.256 243.792 182.488 243.035C183.047 241.885 184.016 241.706 184.967 241.626L184.119 243.066C183.67 243.962 183.715 246.347 184.209 247.67C184.702 249.153 185.511 250.521 186.584 251.687C189.512 255.018 190.135 256.594 193.191 259.362C196.247 262.131 199.001 263.07 198.84 263.688C198.68 264.306 197.556 264.924 194.776 263.181C191.997 261.439 188.356 257.076 186.809 254.746C185.262 252.416 184.126 251.693 182.854 249.196Z" fill="#37474F"/>
      <path d="M197.094 263.392C197.177 263.392 197.258 263.366 197.325 263.317C199.739 261.624 201.331 260.988 204.695 261.099C204.806 261.103 204.914 261.065 204.995 260.992C205.076 260.92 205.124 260.819 205.129 260.713C205.133 260.606 205.093 260.502 205.018 260.424C204.943 260.346 204.838 260.3 204.727 260.295C201.171 260.184 199.399 260.864 196.831 262.669C196.743 262.733 196.684 262.827 196.667 262.932C196.65 263.037 196.677 263.144 196.741 263.231C196.782 263.283 196.835 263.325 196.897 263.353C196.958 263.381 197.026 263.395 197.094 263.392Z" fill="#263238"/>
      <path d="M194.911 261.055C194.999 261.056 195.084 261.03 195.155 260.981C197.794 259.319 199.707 258.806 203.315 258.954C203.371 258.958 203.426 258.95 203.479 258.933C203.532 258.915 203.58 258.888 203.622 258.852C203.664 258.817 203.698 258.774 203.722 258.725C203.747 258.677 203.761 258.625 203.764 258.571C203.768 258.518 203.76 258.464 203.742 258.413C203.724 258.362 203.695 258.316 203.658 258.275C203.621 258.235 203.577 258.203 203.527 258.179C203.476 258.156 203.422 258.142 203.366 258.139C199.559 257.928 197.46 258.534 194.661 260.302C194.613 260.331 194.571 260.369 194.539 260.414C194.507 260.459 194.485 260.51 194.473 260.564C194.462 260.618 194.462 260.673 194.473 260.727C194.484 260.78 194.507 260.831 194.539 260.876C194.582 260.932 194.638 260.977 194.703 261.008C194.768 261.04 194.839 261.056 194.911 261.055Z" fill="#263238"/>
      <path d="M192.716 258.713C192.805 258.713 192.893 258.692 192.972 258.652C195.829 257.014 198.057 256.563 201.915 256.798C202.035 256.81 202.155 256.777 202.25 256.705C202.345 256.634 202.407 256.529 202.423 256.414C202.426 256.357 202.418 256.3 202.398 256.245C202.378 256.191 202.347 256.142 202.307 256.099C202.267 256.056 202.219 256.022 202.165 255.998C202.111 255.973 202.052 255.959 201.992 255.957C197.929 255.654 195.495 256.18 192.465 257.916C192.414 257.944 192.369 257.982 192.334 258.027C192.298 258.072 192.273 258.124 192.26 258.18C192.247 258.235 192.246 258.292 192.257 258.348C192.268 258.404 192.291 258.457 192.324 258.503C192.366 258.567 192.424 258.619 192.492 258.656C192.561 258.692 192.637 258.712 192.716 258.713Z" fill="#263238"/>
      <path d="M190.514 256.377C190.607 256.378 190.7 256.357 190.783 256.315C193.865 254.709 196.413 254.319 200.516 254.672C200.641 254.689 200.768 254.659 200.87 254.588C200.972 254.516 201.041 254.408 201.062 254.288C201.065 254.229 201.056 254.169 201.035 254.112C201.014 254.056 200.982 254.004 200.94 253.96C200.899 253.916 200.848 253.88 200.792 253.854C200.735 253.829 200.674 253.815 200.612 253.813C196.304 253.423 193.55 253.862 190.27 255.574C190.211 255.599 190.16 255.636 190.118 255.683C190.077 255.73 190.047 255.785 190.031 255.844C190.015 255.903 190.013 255.966 190.026 256.026C190.038 256.086 190.064 256.143 190.103 256.192C190.152 256.251 190.215 256.298 190.286 256.33C190.357 256.362 190.435 256.378 190.514 256.377Z" fill="#263238"/>
      <path d="M188.311 254.035C188.411 254.041 188.511 254.022 188.6 253.979C191.9 252.404 194.77 252.07 199.123 252.533C199.252 252.552 199.383 252.523 199.49 252.452C199.597 252.38 199.673 252.273 199.701 252.15C199.708 252.025 199.663 251.902 199.576 251.808C199.49 251.715 199.368 251.658 199.238 251.65C194.674 251.168 191.573 251.532 188.074 253.207C188.014 253.23 187.961 253.266 187.918 253.312C187.874 253.358 187.842 253.412 187.824 253.472C187.805 253.531 187.801 253.593 187.811 253.655C187.821 253.716 187.845 253.774 187.881 253.825C187.931 253.89 187.996 253.942 188.071 253.979C188.145 254.015 188.228 254.035 188.311 254.035Z" fill="#263238"/>
      <path d="M187.541 235.514C188.722 237.8 195.656 243.752 203.707 243.375C203.707 243.375 217.189 230.341 224.623 222.814C231.807 215.528 233.643 210.849 233.117 207.191C232.59 203.532 230.549 199.416 221.907 193.28C211.706 186.018 196.966 177.527 196.966 177.527H166.792C166.792 177.527 162.753 188.385 167.466 195.826C172.178 203.266 177.487 204.75 186.199 208.074C196.067 211.844 201.158 213.81 201.158 213.81C201.158 213.81 199.656 215.237 196.022 221.3C193.146 226.046 187.541 235.514 187.541 235.514Z" fill="#37474F"/>
      <path d="M200.991 193.792C200.619 199.391 196.497 205.269 191.444 207.166L201.171 213.828L181.724 206.412V191.932L200.991 193.792Z" fill="#263238"/>
      <path d="M201.171 213.828L208.271 216.399L198.872 216.831C199.557 215.775 200.326 214.771 201.171 213.828Z" fill="#263238"/>
      <path d="M109.294 199.614C107.291 198.724 105.442 199.237 103.465 200.584C101.487 201.931 100.075 204.515 100.762 208.031C101.449 211.548 102.181 213.414 100.974 216.485C99.7668 219.557 97.9243 224.408 100.268 228.988C102.611 233.567 106.566 234.791 109.018 235.409C111.47 236.027 114.796 235.174 116.022 233.857L109.294 199.614Z" fill="#263238"/>
      <path d="M118.423 203.26C116.915 201.641 113.833 200.467 111.702 199.904C109.57 199.342 106.418 199.367 104.254 201.629C101.686 204.317 101.641 207.104 102.29 210.46C102.938 213.816 102.354 216.022 101.064 219.643C99.7732 223.265 100.094 229.204 104.999 232.374C109.904 235.544 113.897 234.982 116.022 233.864C118.147 232.745 118.449 231.262 117.98 229.581C117.512 227.9 116.022 225.792 115.701 223.37C115.566 222.356 115.406 220.78 115.791 219.798C116.176 218.815 117.55 218.315 117.807 217.159C117.826 217.063 117.826 216.964 117.807 216.869C117.736 216.535 117.338 216.386 117.017 216.251C115.971 215.812 116.709 213.741 116.825 212.87C117.005 211.529 117.178 210.188 117.358 208.841C117.569 207.296 118.218 204.811 118.423 203.26Z" fill="#455A64"/>
      <path d="M116.735 226.639C116.291 226.51 115.899 226.254 115.611 225.904C115.152 225.246 114.849 224.499 114.725 223.716C112.855 223.736 110.989 223.881 109.14 224.149C109.018 224.878 109.23 225.267 109.294 226.312C109.306 226.656 109.205 226.995 109.006 227.281C108.806 227.567 108.519 227.786 108.183 227.906C107.578 228.161 106.906 228.232 106.257 228.11C105.333 227.94 104.458 227.575 103.698 227.041C102.937 226.506 102.31 225.816 101.86 225.02C101.145 223.736 100.75 222.31 100.704 220.855C99.8631 224.47 100.563 229.568 104.973 232.417C109.872 235.582 113.872 235.025 115.997 233.907C118.122 232.788 118.423 231.305 117.955 229.624C117.617 228.604 117.209 227.607 116.735 226.639Z" fill="#37474F"/>
      <path d="M110.328 214.823C109.542 216.724 109.202 218.769 109.333 220.811C109.506 223.611 110.347 224.822 109.23 225.242C108.113 225.663 107.907 224.581 107.734 221.442C107.581 218.971 108.064 216.502 109.14 214.254C109.859 212.461 110.292 210.573 110.424 208.655C110.538 207.522 110.909 206.425 111.51 205.444C112.111 204.463 112.928 203.621 113.904 202.976C114.497 202.589 115.19 202.367 115.906 202.335C116.622 202.303 117.333 202.463 117.961 202.797C117.961 202.797 119.643 203.569 118.603 204.651C118.057 203.736 116.459 202.97 114.359 204.311C113.473 204.92 112.748 205.722 112.245 206.652C111.742 207.583 111.474 208.614 111.464 209.663C111.477 211.282 111.066 212.697 110.328 214.823Z" fill="#37474F"/>
      <path d="M108.928 224.668C108.819 224.67 108.713 224.634 108.63 224.565C108.547 224.497 108.493 224.402 108.479 224.297C108.465 224.193 108.491 224.088 108.552 224.001C108.614 223.914 108.707 223.852 108.813 223.827C110.912 223.277 115.746 223.018 116.401 224.013C116.438 224.06 116.464 224.113 116.478 224.17C116.492 224.228 116.494 224.287 116.483 224.345C116.472 224.403 116.449 224.458 116.415 224.506C116.381 224.555 116.337 224.597 116.285 224.628C116.234 224.66 116.176 224.681 116.116 224.69C116.055 224.699 115.994 224.696 115.935 224.681C115.876 224.667 115.821 224.64 115.773 224.604C115.725 224.567 115.685 224.522 115.656 224.47C115.245 224.136 111.522 224 109.05 224.649L108.928 224.668Z" fill="#263238"/>
      <path d="M115.772 222.109C115.697 222.109 115.622 222.091 115.556 222.057C115.49 222.022 115.434 221.972 115.393 221.911C114.989 221.578 111.265 221.429 108.787 222.072C108.73 222.087 108.671 222.091 108.613 222.084C108.555 222.077 108.499 222.058 108.448 222.03C108.398 222.002 108.353 221.965 108.318 221.92C108.282 221.875 108.256 221.824 108.241 221.769C108.226 221.715 108.222 221.659 108.229 221.603C108.237 221.548 108.256 221.494 108.285 221.446C108.314 221.398 108.353 221.355 108.4 221.322C108.446 221.288 108.499 221.264 108.556 221.25C110.655 220.706 115.489 220.459 116.144 221.454C116.181 221.502 116.208 221.557 116.221 221.615C116.234 221.673 116.235 221.734 116.222 221.792C116.209 221.851 116.184 221.906 116.147 221.954C116.11 222.003 116.063 222.043 116.009 222.072C115.936 222.106 115.853 222.119 115.772 222.109Z" fill="#263238"/>
      <path d="M116.202 219.829C116.117 219.828 116.034 219.805 115.963 219.761C115.891 219.718 115.834 219.655 115.798 219.582C115.438 219.198 111.759 218.605 109.223 218.964C109.166 218.972 109.106 218.97 109.05 218.956C108.993 218.942 108.939 218.918 108.893 218.884C108.846 218.849 108.807 218.807 108.778 218.758C108.748 218.709 108.73 218.655 108.723 218.599C108.708 218.487 108.739 218.374 108.81 218.284C108.881 218.194 108.986 218.134 109.102 218.117C111.252 217.833 116.08 218.173 116.6 219.242C116.626 219.292 116.641 219.347 116.644 219.404C116.648 219.46 116.639 219.516 116.619 219.569C116.6 219.622 116.569 219.671 116.529 219.712C116.49 219.754 116.442 219.787 116.388 219.81C116.329 219.831 116.265 219.838 116.202 219.829Z" fill="#263238"/>
      <path d="M110.578 214.069C109.885 215.225 109.936 216.485 111.002 215.799C112.068 215.113 114.347 216.059 117.197 217.153C120.048 218.247 127.212 221.442 127.212 221.442L136.001 207.228C128.483 205.287 126.731 205.837 118.366 203.662C114.616 202.685 112.189 205.658 111.702 208.476C111.214 211.294 111.194 213.037 110.578 214.069Z" fill="#FFA8A7"/>
      <path d="M157.572 177.527C154.395 182.662 153.72 186.704 153.078 191.932C152.366 197.748 150.71 209.292 150.71 209.292C145.31 207.382 137.959 205.51 125.299 203.73C125.299 203.73 118.237 207.561 119.592 219.378C119.592 219.378 139.044 230.563 149.997 235.347C161.277 240.291 167.228 238.857 172.139 231.935C176.678 225.533 185.448 206.171 185.448 206.171C193.48 205.683 207.514 191.302 204.824 177.514L157.572 177.527Z" fill="#37474F"/>
      <path d="M150.735 209.292C149.74 216.028 149.99 220.607 150.735 224.624C146.492 219.934 148.296 208.476 148.296 208.476C149.182 208.754 150.01 209.032 150.735 209.292Z" fill="#263238"/>
      <path d="M208.348 130.559C220.046 128.989 223.84 135.268 224.623 138.111C225.406 140.953 225.297 144.433 219.596 152.393L208.348 130.559Z" fill="#F0F0F0"/>
      <path d="M154.26 185.159C156.096 175.656 159.071 166.39 163.126 157.547C167.703 147.362 175.837 137.11 179.741 133.958C179.741 133.958 186.443 133.037 196.542 131.313C206.641 129.588 213.574 130.608 217.278 133.266C220.983 135.923 223.294 139.866 222.241 145.471C221.188 151.076 215.821 158.857 212.765 163.467C209.709 168.078 208.509 171.106 208.117 175.049C207.822 178.009 208.412 179.714 207.417 183.299C196.561 197.216 165.732 194.559 154.26 185.159Z" fill="#FAFAFA"/>
      <path d="M205.678 130.46V131.078C210.563 131.078 214.441 132.005 216.887 133.754C220.912 136.64 222.492 140.552 221.593 145.341C220.636 150.434 215.956 157.466 212.861 162.114L212.22 163.109C209.119 167.787 207.88 170.889 207.469 174.968C207.381 175.938 207.351 176.912 207.379 177.885C207.458 179.606 207.27 181.329 206.82 182.996C202.127 188.923 192.953 192.322 181.615 192.322C171.132 192.322 160.738 189.411 154.992 184.906C156.814 175.58 159.74 166.486 163.71 157.8C168.204 147.739 176.133 137.771 179.998 134.563C181.166 134.396 187.573 133.488 196.632 131.943C199.607 131.408 202.625 131.125 205.652 131.096V130.478M205.652 130.478C202.587 130.507 199.53 130.792 196.516 131.331C186.418 133.055 179.715 133.976 179.715 133.976C175.812 137.128 167.678 147.381 163.1 157.565C159.046 166.408 156.07 175.674 154.234 185.178C160.07 189.961 170.926 192.995 181.577 192.995C191.894 192.995 202.057 190.159 207.392 183.317C208.387 179.733 207.796 178.027 208.091 175.067C208.483 171.124 209.684 168.096 212.74 163.486C215.795 158.875 221.163 151.126 222.215 145.49C223.268 139.853 220.964 135.935 217.253 133.284C214.794 131.523 210.923 130.478 205.652 130.478Z" fill="#E0E0E0"/>
      <path d="M195.399 136.139H192.6C194.603 140.972 198.571 147.918 205.954 152.393L206.461 150.273C203.867 148.505 199.482 144.989 195.399 136.139Z" fill="url(#paint6_linear_56_12)"/>
      <path d="M211.308 136.139C211.95 143.184 210.242 149.055 209.465 150.044L210.255 152.454C211.777 150.329 213.574 143.691 212.733 136.139H211.308Z" fill="url(#paint7_linear_56_12)"/>
      <path d="M209.253 154.5C209.253 154.5 212.566 179.739 212.675 182.211C212.784 184.683 208.656 190.641 208.104 190.931C207.552 191.222 204.028 185.332 204.015 183.373C204.002 181.414 207.09 154.438 207.09 154.438L209.253 154.5Z" fill="url(#paint8_linear_56_12)"/>
      <path d="M209.465 150.044C208.45 149.852 207.4 149.931 206.429 150.273C205.844 151.243 205.62 152.745 206.05 153.487C206.48 154.228 207.334 155.341 208.335 155.297C209.337 155.254 210.261 154.024 210.403 152.825C210.576 151.521 209.465 150.044 209.465 150.044Z" fill="url(#paint9_linear_56_12)"/>
      <path opacity="0.2" d="M209.465 150.044C208.45 149.852 207.4 149.931 206.429 150.273C205.844 151.243 205.62 152.745 206.05 153.487C206.48 154.228 207.334 155.341 208.335 155.297C209.337 155.254 210.261 154.024 210.403 152.825C210.576 151.521 209.465 150.044 209.465 150.044Z" fill="white"/>
      <path d="M196.728 101.97C192.17 106.203 193.081 111.648 194.16 117.13C195.444 123.378 195.354 125.367 198.872 127.178C199.643 107.915 204.066 131.931 221.291 118.23C224.405 115.758 225.278 107.421 222.087 103.367C217.561 97.644 205.613 94.0164 196.728 101.97Z" fill="#37474F"/>
      <path d="M194.096 123.198C196.908 124.589 198.391 122.76 198.391 122.76L195.226 129.7C195.226 129.7 193.236 133.995 196.792 136.442C206.66 143.24 204.098 145.57 207.366 148.753C208.65 147.412 209.048 140.984 209.375 139.303L212.22 132.61C213.398 132.741 214.585 132.78 215.77 132.728C218.575 132.567 220.309 130.787 221.413 127.982C223.198 123.476 223.564 116.499 222.594 107.347C219.724 104.751 209.607 103.472 202.692 106.846C202.487 112.167 200.92 112.909 200.734 115.195C200.522 117.76 200.291 118.329 199.45 118.613C198.609 118.897 197.98 117.643 197.71 116.858C197.261 115.548 196.85 114.695 195.579 114.281C194.308 113.867 192.838 113.965 191.509 115.56C189.467 118.038 192.048 122.191 194.096 123.198Z" fill="#FFA8A7"/>
      <path d="M212.22 132.61C208.945 132.203 204.477 130.188 202.756 128.371C201.036 126.554 200.092 123.204 200.092 123.204C199.998 125.426 200.655 127.616 201.967 129.446C204.156 132.474 211.353 134.637 211.353 134.637L212.22 132.61Z" fill="#F28F8F"/>
      <path d="M211.276 109.627C211.186 109.627 211.083 109.683 211.083 109.763C211.083 109.843 211.167 109.88 211.237 109.911C211.351 109.974 211.461 110.044 211.565 110.121C211.671 110.193 211.761 110.286 211.828 110.393C211.869 110.473 211.895 110.559 211.905 110.647C211.932 110.794 211.947 110.942 211.95 111.092C211.95 111.215 212.059 111.296 212.181 111.197C212.226 111.156 212.259 111.105 212.277 111.048C212.349 110.885 212.378 110.706 212.361 110.529C212.348 110.429 212.32 110.331 212.277 110.239C212.189 110.055 212.047 109.9 211.87 109.792C211.692 109.683 211.486 109.626 211.276 109.627ZM216.309 109.775C216.321 109.727 216.319 109.677 216.304 109.63C216.289 109.582 216.261 109.54 216.223 109.506C216.185 109.473 216.138 109.45 216.088 109.44C216.038 109.429 215.985 109.432 215.937 109.448C215.768 109.526 215.619 109.639 215.5 109.779C215.381 109.919 215.296 110.082 215.25 110.257C215.157 110.493 215.135 110.749 215.186 110.996C215.237 111.243 215.359 111.471 215.539 111.654C215.596 111.704 215.686 111.747 215.751 111.704C215.815 111.66 215.751 111.086 215.783 110.919C215.797 110.701 215.863 110.49 215.975 110.301C216.112 110.142 216.224 109.965 216.309 109.775ZM213.561 108.663C213.549 108.656 213.536 108.651 213.522 108.65C213.508 108.648 213.494 108.65 213.481 108.655C213.468 108.66 213.456 108.668 213.447 108.678C213.437 108.687 213.43 108.699 213.426 108.712C213.41 108.771 213.408 108.832 213.422 108.891C213.435 108.951 213.463 109.006 213.504 109.052C213.567 109.178 213.621 109.308 213.664 109.442C213.752 109.704 213.802 109.976 213.812 110.251C213.823 110.51 213.807 110.768 213.767 111.024C213.728 111.265 213.658 111.5 213.619 111.741C213.619 111.741 213.619 111.833 213.67 111.802C213.867 111.611 214.016 111.379 214.107 111.124C214.198 110.87 214.229 110.599 214.197 110.332C214.192 110.034 214.142 109.738 214.049 109.454C213.976 109.148 213.806 108.871 213.561 108.663Z" fill="#F28F8F"/>
      <path d="M206.544 111.858L209.504 110.727C209.653 111.117 209.64 111.547 209.468 111.928C209.296 112.31 208.978 112.612 208.579 112.773C208.384 112.846 208.175 112.88 207.965 112.874C207.755 112.867 207.549 112.819 207.359 112.734C207.169 112.649 206.998 112.527 206.858 112.376C206.718 112.226 206.611 112.049 206.544 111.858Z" fill="#37474F"/>
      <path d="M218.049 110.715L220.944 112.056C220.863 112.242 220.744 112.41 220.593 112.549C220.442 112.689 220.264 112.798 220.068 112.869C219.872 112.94 219.663 112.972 219.454 112.963C219.245 112.954 219.04 112.904 218.851 112.816C218.464 112.63 218.166 112.309 218.017 111.919C217.868 111.528 217.879 111.097 218.049 110.715Z" fill="#37474F"/>
      <path d="M213.362 112.989L213.69 120.516L218.126 119.898C217.426 117.661 215.128 114.077 213.362 112.989Z" fill="#F28F8F"/>
      <path d="M210.159 122.772C209.947 122.88 209.763 123.032 209.619 123.217C209.189 123.798 209.709 124.144 210.261 124.243C210.896 124.348 211.503 124.568 212.053 124.892C212.288 125.079 212.577 125.194 212.881 125.219C213.076 125.201 213.257 125.113 213.388 124.972C213.489 124.819 213.534 124.639 213.516 124.459C213.491 124.094 213.366 123.742 213.154 123.438C212.942 123.134 212.651 122.89 212.309 122.729C211.971 122.577 211.602 122.502 211.229 122.509C210.856 122.517 210.49 122.606 210.159 122.772Z" fill="#B16668"/>
      <path d="M212.072 124.892C212.202 124.975 212.337 125.051 212.476 125.12C212.381 124.534 212.085 123.995 211.635 123.59C211.184 123.184 210.606 122.935 209.992 122.883C209.848 122.973 209.722 123.086 209.619 123.217C209.189 123.798 209.709 124.144 210.261 124.243C210.902 124.346 211.517 124.566 212.072 124.892Z" fill="#F28F8F"/>
      <path d="M210.717 115.937C210.719 116.2 210.638 116.458 210.487 116.677C210.336 116.897 210.121 117.068 209.868 117.169C209.616 117.271 209.338 117.297 209.069 117.246C208.801 117.195 208.554 117.069 208.361 116.882C208.168 116.696 208.036 116.459 207.983 116.201C207.93 115.942 207.958 115.675 208.063 115.432C208.168 115.189 208.346 114.981 208.574 114.836C208.802 114.69 209.07 114.613 209.343 114.614C209.708 114.614 210.057 114.754 210.315 115.002C210.572 115.25 210.717 115.586 210.717 115.937Z" fill="#263238"/>
      <path d="M220.521 115.745C220.522 116.008 220.442 116.266 220.291 116.485C220.14 116.704 219.925 116.876 219.673 116.977C219.421 117.079 219.143 117.106 218.875 117.055C218.607 117.005 218.36 116.879 218.167 116.693C217.973 116.508 217.841 116.271 217.787 116.013C217.733 115.755 217.76 115.488 217.864 115.244C217.969 115.001 218.146 114.793 218.373 114.647C218.6 114.501 218.867 114.423 219.14 114.423C219.321 114.422 219.501 114.456 219.668 114.522C219.835 114.588 219.988 114.685 220.116 114.808C220.244 114.931 220.346 115.077 220.415 115.238C220.485 115.399 220.521 115.571 220.521 115.745Z" fill="#263238"/>
      <path d="M207.321 148.746C205.671 146.621 205.536 140.583 204.278 139.476C203.02 138.37 195.617 133.198 195.168 129.854L196.221 127.524C195.329 127.042 193.839 129.811 190.051 132.58C190.051 132.58 189.326 136.016 191.701 140.175C194.507 145.082 197.171 146.862 197.171 146.862C197.171 146.862 201.466 142.319 202.121 142.183C202.776 142.047 205.209 147.869 207.321 148.746Z" fill="#F0F0F0"/>
      <path d="M207.321 148.746C208.425 147.195 208.823 140.459 209.176 139.594C209.908 137.771 212.014 133.068 212.014 133.068C212.28 133.302 212.517 133.566 212.72 133.853C215.609 138.111 215.031 143.889 214.004 145.595C213.234 143.623 211.224 141.003 210.794 141.466C210.364 141.93 209.048 148.079 207.321 148.746Z" fill="#F0F0F0"/>
      <path d="M136.483 171.303C143.647 167.231 153.894 161.477 153.894 161.477C153.894 161.477 170.541 155.705 174.22 152.98C178.951 149.463 183.458 142.943 179.195 135.935C174.457 134.081 171.863 136.652 161.084 142.226C150.986 147.461 148.45 148.345 144.135 151.966C139.821 155.588 131.405 164.091 129.1 165.371C126.981 166.551 124.15 165.945 122.134 165.884C120.118 165.822 118.449 166.78 116.812 166.347C115.175 165.914 114.244 163.405 112.96 162.293C111.676 161.181 110.719 160.921 110.077 161.366C109.435 161.811 112.093 166.063 112.093 166.063C112.093 166.063 108.838 162.763 107.683 162.101C106.527 161.44 105.172 161.959 105.198 162.571C105.224 163.183 110.623 168.454 111.368 169.295C112.112 170.135 113.968 170.865 116.594 171.594C119.219 172.323 123.758 173.565 126.57 173.707C129.382 173.85 131.925 173.899 136.483 171.303Z" fill="#FFA8A7"/>
      <path d="M112.119 166.063C112.921 167.342 113.628 168.627 114.687 169.153C115.746 169.678 116.96 170.234 116.96 170.234C115.827 169.934 114.727 169.533 113.673 169.035C112.992 168.566 112.119 166.063 112.119 166.063Z" fill="#F28F8F"/>
      <path d="M154.042 161.718L176.595 153.344C180.582 150.254 183.92 140.644 179.741 133.958C172.948 133.958 171.6 135.812 163.139 140.298C157.329 143.388 150.453 146.868 150.453 146.868C146.864 148.518 142.421 152.788 139.346 155.668C136.271 158.548 133.196 161.718 133.196 161.718C133.196 161.718 132.432 163.974 134.993 166.662C136.427 168.247 138.453 169.222 140.63 169.375L154.042 161.718Z" fill="#F0F0F0"/>
      <path d="M52.5477 46.0842L48.2913 57.5976L25.5259 36.7833L52.1625 77.868L56.4575 65.6934L76.4237 88.2133L52.5477 46.0842Z" fill="#F0F0F0"/>
      <path d="M48.7535 163.844L54.0885 172.014L29.5513 175.438L68.816 178.67L63.6479 170.716L87.5688 170.185L48.7535 163.844Z" fill="#F0F0F0"/>
      <path d="M235.543 23.8918L240.102 31.864L254.245 13.9606L239.896 46.591L235.197 39.0452L223.23 57.579L235.543 23.8918Z" fill="#F0F0F0"/>
      <path d="M263.291 144.13L261.056 154.228L287.269 150.903L248.03 164.827L249.86 156.885L224.077 162.04L263.291 144.13Z" fill="#F0F0F0"/>
      <path d="M157.778 88.2195C176.852 87.2863 184.626 92.7803 186.084 99.7266C186.725 102.716 186.223 105.828 184.671 108.49L188.44 112.55L180.826 112.037C178.575 113.206 176.162 114.059 173.661 114.571C164.801 115.931 150.382 115.912 143.224 110.245C142.154 109.41 141.23 108.415 140.489 107.297C137.632 102.953 136.104 89.2824 157.778 88.2195Z" fill="white"/>
      <path d="M153.772 97.885C153.851 97.8865 153.927 97.9177 153.983 97.9718C154.04 98.0259 154.072 98.0989 154.074 98.1754V105.159C154.074 105.197 154.066 105.235 154.051 105.27C154.035 105.305 154.012 105.337 153.984 105.363C153.956 105.39 153.924 105.412 153.887 105.427C153.851 105.442 153.811 105.45 153.772 105.449H152.867C152.785 105.449 152.707 105.418 152.648 105.363C152.593 105.308 152.561 105.235 152.558 105.159V102.304H149.169V105.159C149.169 105.197 149.161 105.235 149.146 105.27C149.13 105.305 149.108 105.337 149.079 105.363C149.052 105.39 149.019 105.412 148.982 105.427C148.946 105.442 148.907 105.45 148.867 105.449H147.955C147.877 105.449 147.801 105.42 147.743 105.369C147.714 105.342 147.691 105.309 147.676 105.273C147.66 105.237 147.653 105.198 147.654 105.159V98.1754C147.653 98.1373 147.661 98.0996 147.676 98.0645C147.692 98.0294 147.715 97.9977 147.743 97.9715C147.77 97.9429 147.803 97.9203 147.839 97.9054C147.876 97.8904 147.916 97.8835 147.955 97.885H148.867C148.946 97.8865 149.022 97.9177 149.078 97.9718C149.135 98.0259 149.167 98.0989 149.169 98.1754V100.95H152.558V98.1754C152.56 98.0978 152.593 98.024 152.651 97.9697C152.709 97.9154 152.786 97.885 152.867 97.885H153.772Z" fill="#455A64"/>
      <path d="M161.058 104.096C161.14 104.096 161.218 104.127 161.277 104.182C161.332 104.237 161.365 104.31 161.367 104.386V105.159C161.365 105.235 161.332 105.308 161.277 105.363C161.218 105.418 161.14 105.449 161.058 105.449H156.128C156.048 105.448 155.973 105.417 155.916 105.362C155.86 105.308 155.828 105.235 155.826 105.159V98.1754C155.826 98.1373 155.834 98.0996 155.849 98.0645C155.865 98.0294 155.887 97.9977 155.916 97.9715C155.943 97.9439 155.976 97.9219 156.013 97.907C156.049 97.8922 156.088 97.8846 156.128 97.885H160.969C161.008 97.8835 161.048 97.8904 161.085 97.9054C161.121 97.9203 161.154 97.9429 161.18 97.9715C161.211 97.9967 161.235 98.028 161.252 98.0632C161.269 98.0984 161.277 98.1367 161.277 98.1754V98.9418C161.277 98.9804 161.269 99.0187 161.252 99.054C161.235 99.0892 161.211 99.1205 161.18 99.1457C161.154 99.1743 161.121 99.1968 161.085 99.2118C161.048 99.2268 161.008 99.2337 160.969 99.2322H157.341V100.95H160.725C160.764 100.95 160.803 100.957 160.84 100.972C160.876 100.987 160.909 101.009 160.936 101.037C160.992 101.091 161.024 101.164 161.026 101.241V102.013C161.025 102.09 160.992 102.163 160.936 102.217C160.88 102.271 160.804 102.302 160.725 102.304H157.341V104.096H161.058Z" fill="#455A64"/>
      <path d="M167.979 104.096C168.059 104.097 168.135 104.128 168.191 104.182C168.247 104.237 168.279 104.31 168.281 104.386V105.159C168.279 105.235 168.247 105.308 168.191 105.363C168.135 105.417 168.059 105.449 167.979 105.449H163.068C162.986 105.449 162.908 105.418 162.85 105.363C162.794 105.308 162.762 105.235 162.76 105.159V98.1754C162.762 98.0978 162.795 98.024 162.852 97.9697C162.91 97.9154 162.987 97.885 163.068 97.885H163.973C164.013 97.8835 164.053 97.8904 164.089 97.9054C164.126 97.9203 164.159 97.9429 164.185 97.9715C164.216 97.9967 164.24 98.028 164.257 98.0632C164.273 98.0984 164.282 98.1367 164.281 98.1754V104.096H167.979Z" fill="#455A64"/>
      <path d="M172.698 97.885C173.454 97.8412 174.202 98.0605 174.804 98.503C175.289 98.9898 175.561 99.6387 175.561 100.314C175.561 100.989 175.289 101.638 174.804 102.124C174.204 102.571 173.455 102.791 172.698 102.742H170.9V105.153C170.898 105.229 170.866 105.302 170.811 105.357C170.755 105.411 170.678 105.442 170.599 105.443H169.687C169.647 105.443 169.608 105.436 169.572 105.421C169.535 105.406 169.502 105.384 169.475 105.357C169.446 105.33 169.424 105.299 169.408 105.264C169.393 105.229 169.385 105.191 169.385 105.153V98.1692C169.387 98.0927 169.419 98.0197 169.475 97.9656C169.532 97.9115 169.607 97.8804 169.687 97.8788L172.698 97.885ZM170.9 101.395H172.64C172.998 101.412 173.352 101.328 173.661 101.154C173.796 101.056 173.903 100.926 173.97 100.776C174.038 100.627 174.064 100.463 174.046 100.301C174.065 100.142 174.039 99.9805 173.971 99.8338C173.904 99.6872 173.796 99.5606 173.661 99.467C173.35 99.2993 172.996 99.218 172.64 99.2322H170.9V101.395Z" fill="#455A64"/>
      <path d="M177.879 103.911C177.96 103.912 178.036 103.944 178.093 104C178.149 104.055 178.181 104.13 178.181 104.207V105.159C178.181 105.197 178.173 105.235 178.158 105.27C178.142 105.305 178.119 105.337 178.091 105.363C178.063 105.39 178.031 105.412 177.994 105.427C177.958 105.442 177.918 105.45 177.879 105.449H176.916C176.834 105.449 176.756 105.418 176.698 105.363C176.644 105.308 176.614 105.235 176.614 105.159V104.207C176.612 104.129 176.642 104.054 176.698 103.997C176.756 103.942 176.834 103.911 176.916 103.911H177.879ZM178.129 102.971C178.129 103.05 178.097 103.125 178.039 103.181C177.98 103.235 177.902 103.263 177.821 103.262H176.967C176.889 103.262 176.813 103.233 176.755 103.181C176.698 103.125 176.666 103.05 176.666 102.971V98.1755C176.665 98.1374 176.673 98.0996 176.688 98.0645C176.704 98.0294 176.727 97.9978 176.755 97.9715C176.783 97.9439 176.816 97.922 176.852 97.9071C176.888 97.8922 176.928 97.8847 176.967 97.885H177.834C177.915 97.885 177.992 97.9154 178.05 97.9697C178.107 98.024 178.14 98.0979 178.142 98.1755L178.129 102.971Z" fill="#455A64"/>
      <defs>
      <linearGradient id="paint0_linear_56_12" x1="141.663" y1="81.0793" x2="225.51" y2="81.0793" gradientUnits="userSpaceOnUse">
      <stop offset="0.261073" stopColor="#161B62"/>
      <stop offset="1" stopColor="#6935FD"/>
      </linearGradient>
      <linearGradient id="paint1_linear_56_12" x1="202.736" y1="76.9967" x2="225.501" y2="76.9967" gradientUnits="userSpaceOnUse">
      <stop offset="0.261073" stopColor="#161B62"/>
      <stop offset="1" stopColor="#6935FD"/>
      </linearGradient>
      <linearGradient id="paint2_linear_56_12" x1="116.683" y1="113.884" x2="220.976" y2="113.884" gradientUnits="userSpaceOnUse">
      <stop offset="0.261073" stopColor="#161B62"/>
      <stop offset="1" stopColor="#6935FD"/>
      </linearGradient>
      <linearGradient id="paint3_linear_56_12" x1="116.683" y1="144.551" x2="151.448" y2="144.551" gradientUnits="userSpaceOnUse">
      <stop offset="0.261073" stopColor="#161B62"/>
      <stop offset="1" stopColor="#6935FD"/>
      </linearGradient>
      <linearGradient id="paint4_linear_56_12" x1="151.448" y1="134.992" x2="220.976" y2="134.992" gradientUnits="userSpaceOnUse">
      <stop offset="0.261073" stopColor="#161B62"/>
      <stop offset="1" stopColor="#6935FD"/>
      </linearGradient>
      <linearGradient id="paint5_linear_56_12" x1="86.3129" y1="136.158" x2="147.508" y2="136.158" gradientUnits="userSpaceOnUse">
      <stop offset="0.261073" stopColor="#161B62"/>
      <stop offset="1" stopColor="#6935FD"/>
      </linearGradient>
      <linearGradient id="paint6_linear_56_12" x1="192.6" y1="144.35" x2="206.461" y2="144.35" gradientUnits="userSpaceOnUse">
      <stop offset="0.261073" stopColor="#161B62"/>
      <stop offset="1" stopColor="#6935FD"/>
      </linearGradient>
      <linearGradient id="paint7_linear_56_12" x1="209.465" y1="144.381" x2="212.948" y2="144.381" gradientUnits="userSpaceOnUse">
      <stop offset="0.261073" stopColor="#161B62"/>
      <stop offset="1" stopColor="#6935FD"/>
      </linearGradient>
      <linearGradient id="paint8_linear_56_12" x1="204.015" y1="172.878" x2="212.677" y2="172.878" gradientUnits="userSpaceOnUse">
      <stop offset="0.261073" stopColor="#161B62"/>
      <stop offset="1" stopColor="#6935FD"/>
      </linearGradient>
      <linearGradient id="paint9_linear_56_12" x1="205.826" y1="152.648" x2="210.421" y2="152.648" gradientUnits="userSpaceOnUse">
      <stop offset="0.261073" stopColor="#161B62"/>
      <stop offset="1" stopColor="#6935FD"/>
      </linearGradient>
      </defs>
      </svg>
    </>
  )
}

export default SectionTwoPicStruggle