import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaMobile } from "react-icons/fa";
import { FaInfinity } from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";

const SectionThreeBenefits = () => {
  const Benefit1 = `دسترسی آسان`;
  const Benefit1_discription = `این هوش مصنوعی به راحتی از طریق وبسایت با چند کلیک و در عرض چند ثانیه در دسترس شماست.`;
  const Benefit2 = `هرچقدر دلت خواست حرف بزن!`;
  const Benefit2_discription = `دیگه محدود به زمان جلسه و اینطور چیزا نمیشی، اشتراک و بگیر و هرچقدر دلت خواست میتونی حرف بزنی.`;
  const Benefit3 = `به صرفه`;
  const Benefit3_discription = `هزینه‌اش نسبت به مشاور حدود یک دهم در ماه هست!`;
  return (
    <>
      <Row className="HomePage sectionThree text-light pt-5 d-sm-flex justify-content-center text-sm-center text-md-end">
        <Col className="col-7 col-md-4 d-flex flex-column align-items-center">
          <FaMobile color="white" size={"50%"} />
          <h6 className="my-3">{Benefit1}</h6>
          <p className=" text-secondary mx-2">{Benefit1_discription}</p>
        </Col>
        <Col className="col-7 col-md-4 d-flex flex-column align-items-center">
          <FaInfinity color="white" size={"50%"} />
          <h6 className="my-3">{Benefit2}</h6>
          <p className=" text-secondary mx-2">{Benefit2_discription}</p>
        </Col>
        <Col className="col-7 col-md-4 d-flex flex-column align-items-center">
          <GiReceiveMoney color="white" size={"50%"} />
          <h6 className="my-3">{Benefit3}</h6>
          <p className=" text-secondary mx-2">{Benefit3_discription}</p>
        </Col>
      </Row>
    </>
  );
};

export default SectionThreeBenefits;
